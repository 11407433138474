/* eslint-disable */
import { Breadcrumb, Col, Form, Icons, List, Modal, Row, Space, Table, TextField, Upload, useTable, Input, Button, CreateButton, Tabs, Badge, EditButton, Tag, Select, Typography, useSelect, useModalForm, Radio, message, RcFile, UploadFile, Switch, Divider, TableProps, Icon, notification, ShowButton, Tooltip } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useNavigation, useTranslate, useUpdate } from "@pankod/refine-core";
import axios from "axios";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { API_URL, TOKEN_KEY, majorCareUrl } from "../../../constants";
import { CheckCircleOutlined, QuestionOutlined, WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";

{/* 
    MAIN STATUS

    649859ca515b80b78fe13b84 = รอดำเนินการ
    649859e7515b80b78fe13b86 = รับเรื่องแจ้งซ่อม
    649859f8515b80b78fe13b88 = นัด วัน/เวลา ประเมินหน้างาน
    64985a01515b80b78fe13b8a = เข้าประเมิน
    64985a0f515b80b78fe13b8c = เข้าประเมิน-เกินกำหนด
    64985a29515b80b78fe13b8e = ประเมินเสร็จ
    64985a35515b80b78fe13b90 = นัด วัน/เวลา เข้าซ่อม
    64985a3c515b80b78fe13b92 = เข้าซ่อม
    64985a47515b80b78fe13b94 = เข้าซ่อม-เกินกำหนด
    64985a67515b80b78fe13b96 = เลื่อนนัดจากลูกค้า *
    64985ae5515b80b78fe13ba2 = เลื่อนนัดจากนิติ *
    64985b3b515b80b78fe13bac = อุปกรณ์อยู่ระหว่างการผลิต *
    64985bbe515b80b78fe13bb6 = รออนุมัติงานซ่อม
    64985bc6515b80b78fe13bb8 = อนุมัติงานซ่อม
    64985bcf515b80b78fe13bba = ปิดงาน *
*/}

{/* 
    SUB STATUS
    
    ******* main = เลื่อนนัดจากลูกค้า ******* option_cust_postpone
    64985a95515b80b78fe13b98 = ลูกค้าติดธุระส่วนตัว
    64985a9c515b80b78fe13b9a = ลูกค้าเป็นโควิด/กักตัว
    64985aaa515b80b78fe13b9c = ลูกค้า ไม่สะดวกนัดวันซ่อม เนื่องจากทำงาน
    64985ab9515b80b78fe13b9e = ลูกค้าไม่มีคนเฝ้าห้องให้ กรณีนัดซ่อมงาน ต่อเนื่องหลายวัน
    64985ac3515b80b78fe13ba0 = ไม่สามารถติดต่อลูกค้าได้

    ******* main = เลื่อนนัดจากนิติ ******* option_juristic_postpone
    64985b04515b80b78fe13ba4 = ผรม. แจ้งว่าไม่สามารถเข้าได้ตามกำหนด
    64985b0d515b80b78fe13ba6 = ผลิตภัณฑ์ที่อยู่ระหว่างผลิต ไม่สามารถเข้าติดตั้งได้
    64985b17515b80b78fe13ba8 = ทางโครงการไม่ให้เข้าแก้ไข ตามวันเวลา ที่แจ้ง
    64985b21515b80b78fe13baa = รอ Major อนุมัติ PO/PR

    ******* main = อุปกรณ์อยู่ระหว่างการผลิต ******* option_matt_in_manufacturing
    64985b5e515b80b78fe13bae = อุปกรณ์ สุขภัณฑ์
    64985b68515b80b78fe13bb0 = วัสดุตกแต่งห้องชุด เช่น กระเบื้อง, พื้นไม้, วอเปเปอร์
    64985b74515b80b78fe13bb2 = อุปกรณ์ที่ต้องสั่งผลิต เช่น เฟอร์นิเจอร์
    64985b80515b80b78fe13bb4 = อุปกรณ์ที่ต้องสั่งผลิต เช่น เครื่องใช้ไฟฟ้า

    ******* main = ปิดงาน ******* option_close_job
    64985be1515b80b78fe13bbc = ส่งงานเสร็จสมบูรณ์
    64985bec515b80b78fe13bbe = Customer เปิดงานซ้ำ
    64985bf6515b80b78fe13bc0 = Customer เปิดงานผิด
    64985c0c515b80b78fe13bc2 = Customer เปิดงานผิด-เจ้าหน้าที่เปิดงานใหม่ให้ลูกค้า
    64985c15515b80b78fe13bc4 = ให้คำแนะนำ เพื่อจัดจ้าง
    64985c22515b80b78fe13bc6 = งานที่ไม่อยู่ในเงื่อนไขรับประกัน
    64985c2d515b80b78fe13bc8 = รายละเอียดไม่ตรงกับรายการรับประกัน
    64985c38515b80b78fe13bca = ลูกค้าเลื่อนซ่อม เกิน SLA
    64985c42515b80b78fe13bcc = รอสั่งของ
    64985c4a515b80b78fe13bce = งานซ่อมที่เกิดจากการใช้งาน
    64985c56515b80b78fe13bd0 = งานเคลมประกันภัยอาคาร

*/}

let dataLocal: any = undefined

export const MjcRepairInprogressJob: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const { data: Identity_data } = useGetIdentity<any>();
    const [dataPermission, setDataPermission] = useState<any>(undefined);
    // let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    // let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const { create, edit } = useNavigation();
    const [countInterval, setCountInterval] = useState(9);
    const [dataList, setDataList] = useState<any>([]);
    const [dataDetailList, setDataDetailList] = useState<any>([]);
    const [SearchStat, setSearchStat] = useState<any>(undefined);
    const [countInprogressJob, setCountInProgressJob] = useState<any>();
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);
    const [isLoading, setIsLoading] = useState<any>(true);
    const dataBlank: any = []

    const [form] = Form.useForm();
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    const [selProp, setselProp] = useState<any>(undefined);
    const [nameSearch, setnameSearch] = useState<any>("");
    const [Unit, setUnit] = useState<any>(undefined);
    const [keepDataDetail, setKeepDataDetail] = useState<any>(undefined)
    const [keepCustomerName, setKeepCustomerName] = useState<any>()

    const option_search: any = [
        {
            label: "เข้าประเมิน",
            value: "64985a01515b80b78fe13b8a",
        },
        {
            label: "เข้าประเมิน-เกินกำหนด",
            value: "64985a0f515b80b78fe13b8c",
        },
        {
            label: "ประเมินเสร็จ",
            value: "64985a29515b80b78fe13b8e",
        },
        {
            label: "เข้าซ่อม",
            value: "64985a3c515b80b78fe13b92",
        },
        {
            label: "เข้าซ่อม-เกินกำหนด",
            value: "64985a47515b80b78fe13b94",
        },
        {
            label: "เลื่อนนัดจากลูกค้า",
            value: "64985a67515b80b78fe13b96",
        },
        {
            label: "เลื่อนนัดจากนิติ",
            value: "64985ae5515b80b78fe13ba2",
        },
        {
            label: "อุปกรณ์อยู่ระหว่างการผลิต",
            value: "64985b3b515b80b78fe13bac",
        },
        {
            label: "นัด วัน/เวลา เข้าซ่อม",
            value: "64985a35515b80b78fe13b90",
        },
        {
            label: "นัด วัน/เวลา ประเมินหน้างาน",
            value: "649859f8515b80b78fe13b88",
        },
        {
            label: "ไม่อนุมัติโดยหัวหน้างาน",
            value: "64a7bf07785d4236922eaff5",
        }
    ];

    const { selectProps: selProperty } = useSelect<any>({
        resource: "properties",
        optionLabel: "property_name",
        metaData: {
            populate: '*',
            // locale:['th']
        },
        optionValue: "id",
        fetchSize: 1000,
        filters: !!prop_list || !!prop_id ? [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field: "id]",
                operator: prop_list ? "in" : "eq",
                value: prop_list ? prop_list : prop_id
            },
        ] : [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
        ]
    });

    const { selectProps: SelUnit } = useSelect<any>({
        resource: "units",
        optionLabel: "unit_name",
        optionValue: "id",
        fetchSize: 10000,
        sort: [{ order: "asc", field: "id" }],
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field: "property][id]",
                operator: "in",
                value: selProp?.id
            },
        ]
    });

    function onSearchtable(data: any, mode: any, type: any) {
        let newData: any = [];
        if (mode == "S") {

            // search job no
            newData = nameSearch !== "" ? dataDetailList?.filter((e: any) => e?.job_obj[0].job_id?.toUpperCase().includes(String(nameSearch.toUpperCase()))) : newData;
            // search prop
            //newData = selProp !== undefined && !Unit? dataDetailList?.filter((item:any)=> item?.job_obj[0].request_by?.unitDetail?.property_id == selProp?.id) : newData;
            // search unit
            //newData = Unit !== undefined && !selProp ? dataDetailList?.filter((item:any)=> item?.job_obj[0].request_by?.unitDetail?.unit_id == Unit?.id) : newData;
            // search stat
            newData = SearchStat !== undefined ? dataDetailList?.filter((item: any) => item?.job_obj[0].status_id == SearchStat?.value) : newData;
            // search detail
            newData = keepDataDetail !== undefined ? dataDetailList?.filter((item: any) => item?.zone_obj[0]?.zone_name?.toLowerCase().includes(String(keepDataDetail?.toLowerCase())) ||
                item?.category_obj[0]?.category_name?.toLowerCase().includes(String(keepDataDetail?.toLowerCase())) ||
                item?.category_detail_obj[0]?.category_detail_name?.toLowerCase().includes(String(keepDataDetail?.toLowerCase()))
            ) : newData
            // search CustomerName
            newData = keepCustomerName !== undefined ? dataDetailList?.filter((item: any) => item?.job_obj[0]?.request_by?.attributes?.first_name?.toLowerCase().includes(String(keepCustomerName?.toLowerCase())) ||
                item?.job_obj[0]?.request_by?.attributes?.last_name?.toLowerCase().includes(String(keepCustomerName?.toLowerCase()))
            ) : newData

            // Prop
            if (!!selProp && !Unit) {
                newData = dataDetailList?.filter((item: any) => item?.job_obj[0].request_by?.unitDetail?.property_id == selProp?.id)
                setDataDetailList(newData);
            }
            // Unit
            if (!selProp && !!Unit) {
                newData = dataDetailList?.filter((item: any) => item?.job_obj[0].request_by?.unitDetail?.unit_id == Unit?.id)
                setDataDetailList(newData);
            }
            // Prop && Unit
            if (!!selProp && !!Unit) {
                newData = dataDetailList?.filter((item: any) => item?.job_obj[0].request_by?.unitDetail?.property_id == selProp?.id
                    && item?.job_obj[0].request_by?.unitDetail?.unit_name == Unit?.label
                )
                setDataDetailList(newData);
            }

            setCountInProgressJob(newData)
            // setDataDetailList(newData);
        } else if (mode == "R") {
            setDataDetailList(dataLocal)
            form?.resetFields();
            setselProp(undefined);
            setUnit(undefined)
            setKeepDataDetail(undefined)
            setKeepCustomerName(undefined);
            // refreshDataSource();
            setnameSearch("");
            setSearchStat(undefined)
        }
    }

    const getDataK = async () => {

        // GET JOB DETAIL DATA
        let res_detail_data = await axios.get(
            // majorCareUrl + `/majorcare/repairJobDetail/findRepairJobDetailByJobId`, 
            //majorCareUrl + `/majorcare/repairJobDetail/findRepairJobDetailByStat?stat=64985a01515b80b78fe13b8a,64985a0f515b80b78fe13b8c,64985a29515b80b78fe13b8e,64985a3c515b80b78fe13b92,64985a47515b80b78fe13b94,64985a67515b80b78fe13b96,64985ae5515b80b78fe13ba2,64985b3b515b80b78fe13bac,64985a35515b80b78fe13b90,649859f8515b80b78fe13b88,64a7bf07785d4236922eaff5&page=1&limit=500`, 
            API_URL + `/api/repair-settings/findRepairJobDetailByStat?stat=64985a01515b80b78fe13b8a,64985a0f515b80b78fe13b8c,64985a29515b80b78fe13b8e,64985a3c515b80b78fe13b92,64985a47515b80b78fe13b94,64985a67515b80b78fe13b96,64985ae5515b80b78fe13ba2,64985b3b515b80b78fe13bac,64985a35515b80b78fe13b90,649859f8515b80b78fe13b88,64a7bf07785d4236922eaff5&page=1&limit=500`,
            { headers: { Authorization: `Bearer ${TOKEN_KEY}` } }).then((res) => {
                let dataLoad: any = res?.data;
                console.log('dataLoad Detail', dataLoad)
                // console.log('prop_id', prop_id)
                // console.log('prop_list', prop_list)
                // if(prop_id !== null){
                //     dataLoad = dataLoad?.filter((e:any)=> e.job_obj[0].request_by?.unitDetail?.property_id == Number(prop_id))
                // }

                dataLoad?.reverse()
                if (prop_list !== undefined) {
                    console.log('case 1')
                    dataLoad = dataLoad.filter((item: any) => prop_list?.includes(item.job_obj[0]?.request_by?.unitDetail?.property_id));
                } else if (prop_id !== null) {
                    console.log('case 2')
                    dataLoad = dataLoad?.filter((e: any) => e.job_obj[0]?.request_by?.unitDetail?.property_id == Number(prop_id))
                }

                // ระหว่างดำเนินการ ทุกสถานะ ที่ไม่ใช่ ระหว่างดำเนินการ, นัด วัน / เวลา ประเมินหน้างาน, นัด วัน / เวลา เข้าซ่อม, รออนุมัติงานซ่อม, อนุมัติงานซ่อม , ปิดงาน , เลื่อนนัดจากลูกค้า ที่ยังไม่ได้รับการอนุม้ติจากหัวหน้างาน , เลื่อนนัดจากนิติ ที่หวหน้ายังไม่อนุม้ติ
                {/* 
                    MAIN STATUS

                    649859f8515b80b78fe13b88 = นัด วัน/เวลา ประเมินหน้างาน
                    64985a01515b80b78fe13b8a = เข้าประเมิน
                    64985a0f515b80b78fe13b8c = เข้าประเมิน-เกินกำหนด
                    64985a29515b80b78fe13b8e = ประเมินเสร็จ
                    64985a35515b80b78fe13b90 = นัด วัน/เวลา เข้าซ่อม
                    64985a3c515b80b78fe13b92 = เข้าซ่อม
                    64985a47515b80b78fe13b94 = เข้าซ่อม-เกินกำหนด
                    64985b3b515b80b78fe13bac = อุปกรณ์อยู่ระหว่างการผลิต *
                    64a7bf07785d4236922eaff5 = ไม่อนุมัติโดยหัวหน้างาน
                */}

                // {
                //     label: "ไม่อนุมัติโดยหัวหน้างาน",
                //     value: "64a7bf07785d4236922eaff5",
                // },

                // const xx = dataLoad.filter((e:any) => (
                //     e.job_obj[0]?.status_id == "64985a01515b80b78fe13b8a" || 
                //     e.job_obj[0]?.status_id == "64985a0f515b80b78fe13b8c" || 
                //     e.job_obj[0]?.status_id == "64985a29515b80b78fe13b8e" || 
                //     e.job_obj[0]?.status_id == "64985a3c515b80b78fe13b92" || 
                //     e.job_obj[0]?.status_id == "64985a47515b80b78fe13b94" || 
                //     e.job_obj[0]?.status_id == "64985a67515b80b78fe13b96" || 
                //     e.job_obj[0]?.status_id == "64985ae5515b80b78fe13ba2" || 
                //     e.job_obj[0]?.status_id == "64985b3b515b80b78fe13bac" ||
                //     e.job_obj[0]?.status_id == "64985a35515b80b78fe13b90" ||
                //     e.job_obj[0]?.status_id == "649859f8515b80b78fe13b88" ||
                //     e.job_obj[0]?.status_id == "64a7bf07785d4236922eaff5"
                // ));

                // const xx = dataLoad.filter((e:any) => (
                //     e.job_obj[0].status_id == "64985a01515b80b78fe13b8a"
                // ));

                // console.log('xx', xx)
                dataLocal = dataLoad

                // let xx = dataLoad.filter((e:any)=> e.job_obj.length > 0 && e.job_obj[0].status_id == "649859ca515b80b78fe13b84")
                // let xx = dataLoad.filter((e:any)=> e.job_obj.length > 0 && e.job_obj[0].is_delegate == false)
                let keepData = []
                for (let index = 0; index < dataLoad.length; index++) {
                    keepData.push(dataLoad[index]);
                    //dataDetailList.push(dataLoad[index]);
                }
                if (!!keepData && keepData !== null) {
                    setDataDetailList(keepData)
                } else {
                    setDataDetailList(dataLoad)
                }
                setCountInProgressJob(dataLoad)
                // dataDetailList?.reverse()
                setIsLoading(false)

            });
    }
    // console.log('dataList', dataList)
    console.log('dataDetailList', dataDetailList)
    const { confirm } = Modal;
    const successNoti = (stat: any, msg: any) => {
        // notification.success({
        //     // message: stat + ' : ' + msg.TH,
        //     message: msg,
        //     // description: msg,
        //     duration: 3,
        // });

        notification['success']({
            message: "Successful",
            description:
                'Successfully updated',
        });
    };

    useEffect(() => {
        const data_permission: any = Identity_data?.menu_access?.find((item: any) => item?.module_master?.module_name == "แจ้งซ่อม Majorcare (Admin)");
        setDataPermission(data_permission)
    }, [Identity_data]);

    useEffect(() => {
        if (countInterval < 3) {
            const interval = setInterval(() => {
                setCountInterval(prev => prev + 1);
                if (countInterval === 1) {
                    getDataK();
                }
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [countInterval])

    useEffect(() => {
        getDataK();
    }, []);

    async function updateStatus(id: any, val: any) {
        // console.log('id', id)
        let data = {
            "is_active": val,
        }
        let res_update = await axios.put(
            majorCareUrl + `/majorcare/updateHomecheckjob?id=` + id, data,
            { headers: {} }).then((res) => {
                let dataLoad: any = res?.data;
                //console.log('dataLoad Check', dataLoad)
            }
            );
        refreshDataSource()
    }

    const [refreshFlag, setRefreshFlag] = useState(false);

    const clearDataSource = () => {
        setDataList([])
        setDataDetailList([])
    }

    const refreshDataSource = () => {
        clearDataSource()
        setCountInterval(0)
        setRefreshFlag(!refreshFlag);
    };

    function showDelConfirm(id: any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title: 'Delete Confirm ?',
            icon: <WarningOutlined />,
            content: 'Click Confirm to Delete this data.',
            okText: 'Confirm',
            onOk() {
                updateStatus(id, false);
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 2000); // orginal = 1000
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id: any) {
        // console.log('id', id)
        confirm({
            title: 'Restore Confirm ?',
            icon: <QuestionCircleOutlined />,
            content: 'Click Confirm to restore this data.',
            okText: 'Confirm',
            onOk() {
                updateStatus(id, true);
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 2000); // orginal = 1000
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    const columns: any = [
        {
            title: 'JOB NO.',
            dataIndex: 'job_id',
            key: 'job_id',
            width: 150,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a: any, b: any) => {
                if (a?.job_obj[0]?.job_id < b?.job_obj[0]?.job_id) { return -1 }
                if (a?.job_obj[0]?.job_id > b?.job_obj[0]?.job_id) { return 1 }
                return (0)
            },
            render: (value: any, record: any) => {
                return (
                    <>
                        {/* <div>{record ? record.job_id !== null ? record.job_id : '-': '-'}</div> */}
                        <div>{record ? record.job_obj[0].job_id !== null ? record.job_obj[0].job_id : '-' : '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'PROPERTY',
            // dataIndex: 'name',
            key: 'property',
            width: 180,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a: any, b: any) => {
                if (a?.job_obj[0]?.request_by?.unitDetail?.property_name < b?.job_obj[0]?.request_by?.unitDetail?.property_name) { return -1 }
                if (a?.job_obj[0]?.request_by?.unitDetail?.property_name > b?.job_obj[0]?.request_by?.unitDetail?.property_name) { return 1 }
                return (0)
            },
            render: (value: any, record: any) => {
                return (
                    <>
                        {/* <div>{record ? record.property_obj.length > 0 ? record.property_obj[0].property_name : '-': '-'}</div> */}
                        <div>{record ? record.job_obj[0].request_by ? record.job_obj[0].request_by.unitDetail.property_name : '-' : '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'UNIT NO.',
            dataIndex: 'unit',
            key: 'unit',
            width: 120,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a: any, b: any) => {
                if (a?.job_obj[0]?.request_by?.unitDetail?.unit_name < b?.job_obj[0]?.request_by?.unitDetail?.unit_name) { return -1 }
                if (a?.job_obj[0]?.request_by?.unitDetail?.unit_name > b?.job_obj[0]?.request_by?.unitDetail?.unit_name) { return 1 }
                return (0)
            },
            render: (value: any, record: any) => {
                return (
                    <>
                        {/* <div>{record ? record.unit_obj.length > 0 ? record.unit_obj[0].unit_name : '-': '-'}</div> */}
                        <div>{record ? record.job_obj[0].request_by ? record.job_obj[0].request_by.unitDetail.unit_name : '-' : '-'}</div>

                    </>
                );
            },
            // sorter: (a:any, b:any) => a.createBy.first_name.localeCompare(b.createBy.first_name),
        },
        {
            title: 'รายละเอียด',
            dataIndex: 'details',
            key: 'details',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a: any, b: any) => {
                if (a?.zone_obj[0]?.zone_name < b?.zone_obj[0]?.zone_name) { return -1 }
                if (a?.zone_obj[0]?.zone_name > b?.zone_obj[0]?.zone_name) { return 1 }
                return (0)
            },
            render: (value: any, record: any) => {
                return (
                    <>
                        {/* <div>{record ? record.unit_obj.length > 0 ? record.unit_obj[0].unit_name : '-': '-'}</div> */}
                        {/* <div>{record ? record.unit.attributes !== undefined ? record.unit.attributes.unit_name : '-': '-'}</div> */}
                        <div>{record ? record.zone_obj[0] !== undefined ? record.zone_obj[0].zone_name : '-' : '-'} - {record ? record.category_obj[0] !== undefined ? record.category_obj[0].category_name : '-' : '-'} - {record ? record.category_detail_obj[0] !== undefined ? record.category_detail_obj[0].category_detail_name : '-' : '-'} </div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.createBy.first_name.localeCompare(b.createBy.first_name),
        },
        {
            title: 'CUSTOMER NAME',
            // dataIndex: 'updateBy',
            key: 'cus_name',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a: any, b: any) => {
                if (a?.job_obj[0]?.request_by?.attributes?.first_name < b?.job_obj[0]?.request_by?.attributes?.first_name) { return -1 }
                if (a?.job_obj[0]?.request_by?.attributes?.first_name > b?.job_obj[0]?.request_by?.attributes?.first_name) { return 1 }
                return (0)
            },
            render: (value: any, record: any) => {
                return (
                    <>
                        <div>{
                            record ?
                                record.job_obj[0].request_by.attributes !== undefined ?
                                    record.job_obj[0].request_by.attributes.first_name + " " + record.job_obj[0].request_by.attributes.last_name
                                    : '-'
                                : '-'
                        }</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: 'TRANSFERRED DATE',
            dataIndex: 'transfer_date',
            key: 'transfer_date',
            align: 'center',
            sorter: (a: any, b: any) => {
                // if (a?.job_obj[0]?.request_by?.unitDetail?.transfer_date < b?.job_obj[0]?.request_by?.unitDetail?.transfer_date) {return -1}
                // if (a?.job_obj[0]?.request_by?.unitDetail?.transfer_date > b?.job_obj[0]?.request_by?.unitDetail?.transfer_date) {return 1}
                if (a?.lscUnit?.owner_in_date < b?.lscUnit?.owner_in_date) { return -1 }
                if (a?.lscUnit?.owner_in_date > b?.lscUnit?.owner_in_date) { return 1 }
                return (0)
            },
            render: (value: any, record: any) => {
                if (record?.job_obj.length > 0) {
                    //let dateString = record?.job_obj[0]?.request_by?.unitDetail?.transfer_date
                    let dateString = record?.lscUnit?.owner_in_date
                    if (dateString) {
                        //const hasT000000Format = dateString.includes("T00:00:00.000") || dateString.includes("T17:00:00.000") || dateString.includes("T07:00:00.000") ;
                        const hasT000000Format = dateString.includes("T");
                        if (hasT000000Format) {
                            const formattedDate = dayjs(dateString).format('DD/MM/YY');
                            return formattedDate;
                        } else {
                            return dateString
                        }
                    } else {
                        return "-"
                    }
                } else {
                    return (<div>-</div>)
                }
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: 'CREATED DATE',
            dataIndex: 'create_date',
            key: 'create_date',
            align: 'center',
            sorter: (a: any, b: any) => {
                if (a?.job_obj[0]?.create_date < b?.job_obj[0]?.create_date) { return -1 }
                if (a?.job_obj[0]?.create_date > b?.job_obj[0]?.create_date) { return 1 }
                return (0)
            },
            render: (value: any, record: any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].create_date !== null ? dayjs(record.job_obj[0].create_date).format("DD/MM/YY HH:mm") : '-' : '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.create_date.localeCompare(b.create_date),
        },
        {
            title: 'APPOINTMENT DATE',
            dataIndex: 'appoint_start',
            key: 'appoint_start',
            align: 'center',
            sorter: (a: any, b: any) => {
                if (a?.job_obj[0]?.appoint_start < b?.job_obj[0]?.appoint_start) { return -1 }
                if (a?.job_obj[0]?.appoint_start > b?.job_obj[0]?.appoint_start) { return 1 }
                return (0)
            },
            render: (value: any, record: any) => {
                console.log('record', record)
                return (
                    <>
                        <div>
                            {record ? record?.job_obj?.[0]?.appoint_start != null
                                ? dayjs(record.job_obj[0].appoint_start).format('DD/MM/YYYY HH:mm')
                                : '-': '-'}
                        </div>

                    </>
                );
            },
            // sorter: (a:any, b:any) => a.create_date.localeCompare(b.create_date),
        },
        {
            title: 'ผ่านมาแล้วกี่วัน',
            dataIndex: 'day_count',
            key: 'day_count',
            align: 'center',
            sorter: (a: any, b: any) => {
                if (a?.job_obj[0]?.create_date < b?.job_obj[0]?.create_date) { return -1 }
                if (a?.job_obj[0]?.create_date > b?.job_obj[0]?.create_date) { return 1 }
                return (0)
            },
            render: (value: any, record: any) => {
                // const date1 = dayjs()
                // date1.diff(dayjs(record.job_obj[0].create_date).format('YYYY-MM-DD'), 'day',true) // 7.645161290322581

                const date1 = dayjs(record.job_obj[0].create_date);
                const date2 = dayjs();

                let hours = date2.diff(date1, 'hours');
                const days = Math.floor(hours / 24);
                // hours = hours - (days * 24);
                // console.log('Days: ', days);
                // console.log('Hours: ', hours);
                return (
                    <>
                        <div> {days} </div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.create_date.localeCompare(b.create_date),
        },
        {
            title: 'วันที่แก้ไขแล้วเสร็จ',
            dataIndex: 'job_fin_date',
            key: 'job_fin_date',
            align: 'center',
            // sorter: (a:any, b:any) => {
            //     if (a?.job_obj[0]?.inspec_to_cust_approve_date < b?.job_obj[0]?.inspec_to_cust_approve_date) {return -1}
            //     if (a?.job_obj[0]?.inspec_to_cust_approve_date > b?.job_obj[0]?.inspec_to_cust_approve_date) {return 1}
            //     return (0)
            // },
            render: (value: any, record: any) => {
                // 1. หาของใน trail_obj ที่ status = "64985bbe515b80b78fe13bb6" และ status_name = "รออนุมัติงานซ่อม"
                // 2. เอา create_date มาแสดง
                const statusToFind = '64985bbe515b80b78fe13bb6'; // The status to find
                const filteredEntries = record?.job_trails_obj.filter((entry: any) => entry.status === statusToFind)
                let latestEntry
                if (filteredEntries.length > 0) {
                    latestEntry = filteredEntries[0];
                } else {
                    // console.log('No entry found with status', statusToFind);
                }
                return (
                    <>
                        {/* <div>{record ? record.job_obj[0]?.inspec_to_cust_approve_date ? dayjs(record.job_obj[0]?.inspec_to_cust_approve_date).format("DD/MM/YY HH:mm") : '-': '-'}</div> */}
                        <div>{record ? latestEntry ? dayjs(latestEntry?.create_date).format("DD/MM/YY HH:mm") : '-' : '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'ช่างผู้รับผิดชอบ',
            // dataIndex: 'inspec_name',
            width: 230,
            key: 'inspec_name',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a: any, b: any) => {
                if (a?.job_obj[0]?.assign?.attributes?.user_profile?.data?.attributes?.first_name < b?.job_obj[0]?.assign?.attributes?.user_profile?.data?.attributes?.first_name) { return -1 }
                if (a?.job_obj[0]?.assign?.attributes?.user_profile?.data?.attributes?.first_name > b?.job_obj[0]?.assign?.attributes?.user_profile?.data?.attributes?.first_name) { return 1 }
                return (0)
            },
            render: (value: any, record: any) => {
                //console.log('record', record)
                return (
                    <>
                        <div>
                            {
                                record ?
                                    record.job_obj[0]?.assign !== null ?
                                        record.job_obj[0]?.assign?.attributes?.user_profile ?
                                            record.job_obj[0]?.assign?.attributes?.user_profile?.data?.attributes?.first_name + ' ' + record.job_obj[0]?.assign?.attributes?.user_profile?.data?.attributes?.last_name
                                            : '-'
                                        : '-'
                                    : '-'
                            }
                        </div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.create_date.localeCompare(b.create_date),
        },
        {
            title: 'CREATED BY',
            dataIndex: 'create_by',
            key: 'create_by',
            width: 150,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a: any, b: any) => {
                if (a?.job_obj[0]?.create_by?.first_name < b?.job_obj[0]?.create_by?.first_name) { return -1 }
                if (a?.job_obj[0]?.create_by?.first_name > b?.job_obj[0]?.create_by?.first_name) { return 1 }
                return (0)
            },
            render: (createBy: any, record: any) => {
                return (
                    <>
                        <div >{record ? record?.job_obj[0]?.create_by ? record?.job_obj[0]?.create_by?.first_name : "-" : "-"} {record?.job_obj[0]?.create_by?.last_name ? record?.job_obj[0]?.create_by?.last_name : ""}</div>
                    </>
                );
            },
        },
        {
            title: 'UPDATE BY',
            dataIndex: 'update_by',
            key: 'update_by',
            width: 150,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a: any, b: any) => {
                if (a?.job_obj[0]?.update_by?.first_name < b?.job_obj[0]?.update_by?.first_name) { return -1 }
                if (a?.job_obj[0]?.update_by?.first_name > b?.job_obj[0]?.update_by?.first_name) { return 1 }
                return (0)
            },
            render: (updateBy: any, record: any) => {
                return (
                    <>
                        <div >{record ? record?.job_obj[0]?.update_by ? record?.job_obj[0]?.update_by?.first_name : "-" : "-"} {record?.job_obj[0]?.update_by?.last_name ? record?.job_obj[0]?.update_by?.last_name : ""}</div>
                    </>
                );
            },
        },
        {
            title: 'Last Update',
            dataIndex: 'update_date',
            key: 'update_date',
            width: 150,
            align: 'center',
            sorter: (a: any, b: any) => a.job_obj[0]?.update_date.localeCompare(b.job_obj[0]?.update_date),
            render: (value: any, record: any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].update_date !== null ? dayjs(record.job_obj[0].update_date).format("DD/MM/YY HH:mm") : '-' : '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            key: 'status',
            width: 230,
            fixed: 'right',
            align: 'center',
            sorter: (a: any, b: any) => {
                if (a?.job_obj[0]?.status_name < b?.job_obj[0]?.status_name) { return -1 }
                if (a?.job_obj[0]?.status_name > b?.job_obj[0]?.status_name) { return 1 }
                return (0)
            },
            render: (value: any, record: any) => {
                return (
                    <>
                        <div>
                            <Tag className="tag_mjc_stat_wait_inspect">{record.job_obj[0].status_name}</Tag>
                        </div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: 'SLA',
            dataIndex: 'sla',
            key: 'sla',
            width: 100,
            fixed: 'right',
            align: 'center',
            sorter: (a: any, b: any) => {
                if (a?.job_obj[0]?.sla?.sla < b?.job_obj[0]?.sla?.sla) { return -1 }
                if (a?.job_obj[0]?.sla?.sla > b?.job_obj[0]?.sla?.sla) { return 1 }
                return (0)
            },
            render: (value: any, record: any) => {
                return (
                    <>
                        {
                            record.job_obj[0].sla != undefined ?
                                record.job_obj[0].sla.sla == "Y" ?
                                    <div style={{ color: 'green' }}>On Time</div>
                                    : record.job_obj[0].sla.sla == "N" ?
                                        <div style={{ color: 'red' }}>Over Time</div>
                                        :
                                        '-'
                                : '-'
                        }
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: 'ACTION',
            dataIndex: '',
            key: 'action',
            fixed: 'right',
            width: 120,
            align: 'center',
            render: (value: any, record: any) => {
                // console.log('record', record)
                return (
                    <>
                        {
                            dataPermission?.update == true ? record.job_obj[0].is_active === true ?
                                <>
                                    {/* {
                                        record.is_checklist_empty == true &&
                                        <Tooltip title="No template">
                                            <WarningOutlined style={{ color: "#FFE58F", fontSize: '20px', background: 'white', padding: '4px', }} />
                                        </Tooltip>
                                    } */}
                                    {/* <ShowButton
                                        hideText
                                        size="small"
                                        onClick={() => edit("mjc-job-newjob", record._id + "_" + record.job_id )}
                                        // disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                        style={{
                                            marginRight:'4px'
                                        }}
                                    /> */}
                                    <EditButton
                                        hideText
                                        size="small"
                                        style={{ marginRight: '5px' }}
                                        onClick={() => edit("repair-inprogress-job-admin", record._id + "_" + record.job_id)}
                                    // disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                    />
                                    <Button
                                        size="small"
                                        title="Delete"
                                        icon={<Icons.DeleteOutlined />}
                                        onClick={() => { showDelConfirm(record._id) }}
                                        danger
                                    ></Button>

                                </>
                                :
                                <>
                                    <Button
                                        size="small"
                                        title="Active"
                                        icon={<Icons.RetweetOutlined />}
                                        onClick={() => { showUnlockConfirm(record._id) }}
                                    ></Button>
                                </>
                                :
                                <></>
                        }
                    </>
                );
            },
        },
    ];

    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    const [pageSize, setPageSize] = useState(10); // Default page size
    // const [pageSizeInActive, setPageSizeInActive] = useState(10); // Default page size
    const handlePageSizeChange = (current: any, size: any) => {
        setPageSize(size);
    };

    return <>
        <Row>
            <Col span={24}>
                <Form layout="vertical"
                    onFinish={(values: any) => { }}
                    form={form}
                >
                    <Row gutter={8} style={{ width: "100%" }}>
                        <Col className="gutter-row">
                            <Form.Item
                                name="property"
                                // label="Search"
                                style={{ width: "200px", marginRight: "0" }}
                            >
                                <Select
                                    placeholder="Property"
                                    {...selProperty} allowClear
                                    style={{ borderRadius: 5, position: 'relative' }}
                                    suffixIcon={<Icons.SearchOutlined />}
                                    onChange={(value: any, record: any) => {
                                        setselProp({ id: value, label: record?.label })
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row">
                            <Form.Item

                                name="unit"
                                style={{ width: "120px", marginRight: "0" }}
                            >
                                <Select {...SelUnit}
                                    placeholder="Unit"
                                    size="middle"
                                    style={{ borderRadius: 5, position: 'relative' }}
                                    // onSelect={(e: any)=>setUnitID(e)}
                                    suffixIcon={<Icons.SearchOutlined />}
                                    onChange={(value: any, record: any) => {
                                        setUnit({ id: value, label: record?.label })
                                    }}
                                    onClear={() => {
                                        setUnit(undefined);
                                        form.resetFields(["unit"]);
                                    }}
                                    allowClear
                                    showSearch
                                />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row">
                            <Form.Item name="template_name" style={{ width: "150px", marginRight: "0" }}>
                                <Input
                                    style={{ borderRadius: 5, position: 'relative' }}
                                    placeholder="Job No."
                                    onChange={(e) => { if (e.target.value.length > 0) { setnameSearch(e?.target?.value) } else { setnameSearch("") } }}
                                    suffix={<Icons.SearchOutlined />}
                                    allowClear />
                            </Form.Item>
                        </Col>

                        <Col className="gutter-row">
                            <Form.Item name="cust_name" style={{ width: "180px", marginRight: "0" }}>
                                <Input
                                    style={{ borderRadius: 5, position: 'relative' }}
                                    placeholder="Customer name"
                                    onChange={(e) => setKeepCustomerName(e.target.value)}
                                    suffix={<Icons.SearchOutlined />}
                                    allowClear
                                />
                            </Form.Item>
                        </Col>

                        <Col className="gutter-row">
                            <Form.Item name="detail" style={{ width: "180px", marginRight: "0" }}>
                                <Input
                                    style={{ borderRadius: 5, position: 'relative' }}
                                    placeholder="รายละเอียด"
                                    onChange={(e) => setKeepDataDetail(e.target.value)}
                                    suffix={<Icons.SearchOutlined />}
                                    allowClear
                                />
                            </Form.Item>
                        </Col>

                        <Col className="gutter-row">
                            <Form.Item >
                                <Button type="primary" style={{ borderRadius: "5px" }} onClick={() => onSearchtable(dataDetailList, "S", "xxxxxx")}>
                                    <Icons.SearchOutlined /> Search
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row">
                            <Form.Item >
                                <Button
                                    onClick={() => {
                                        onSearchtable(dataDetailList, "R", "xxxx")
                                    }}
                                    style={{ borderRadius: "5px" }}
                                    icon={<Icons.UndoOutlined />}
                                >
                                    Reset
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>

        <Table
            dataSource={dataDetailList.length > 0 ? dataDetailList.reverse() : dataBlank}
            columns={columns}
            loading={isLoading}
            onChange={onChange}
            pagination={{
                size: "small",
                position: ['bottomRight'],
                pageSizeOptions: ['10', '20', '50'], // Optional - Customize the available page sizes
                showSizeChanger: true, // Optional - Show page size changer dropdown
                pageSize: pageSize,
                onChange: handlePageSizeChange,
            }}
            scroll={{ x: 2800 }}
        />

        <Row style={{ float: 'right', paddingTop: '10px' }}>
            <Col span={24} >
                {t("repair-stat.title")} {!!countInprogressJob ? countInprogressJob?.length : 0}
            </Col>
        </Row>
    </>
}