/* eslint-disable */
import { List, Table, TextField, EditButton, Tag, TableProps, Row, Button, Icons, notification, Modal, Checkbox, useSelect, Form, Col, Select, Input } from "@pankod/refine-antd";
import { IResourceComponentsProps, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core";
import axios from "axios";
import { useEffect, useState } from "react";
import { API_URL, TOKEN_KEY, majorCareUrl } from "../../../constants";
import dayjs from "dayjs";
import { CheckCircleOutlined, QuestionOutlined, WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";


{/* 
    MAIN STATUS

    649859ca515b80b78fe13b84 = รอดำเนินการ
    649859e7515b80b78fe13b86 = รับเรื่องแจ้งซ่อม
    649859f8515b80b78fe13b88 = นัด วัน/เวลา ประเมินหน้างาน
    64985a01515b80b78fe13b8a = เข้าประเมิน
    64985a0f515b80b78fe13b8c = เข้าประเมิน-เกินกำหนด
    64985a29515b80b78fe13b8e = ประเมินเสร็จ
    64985a35515b80b78fe13b90 = นัด วัน/เวลา เข้าซ่อม
    64985a3c515b80b78fe13b92 = เข้าซ่อม
    64985a47515b80b78fe13b94 = เข้าซ่อม-เกินกำหนด
    64985a67515b80b78fe13b96 = เลื่อนนัดจากลูกค้า *
    64985ae5515b80b78fe13ba2 = เลื่อนนัดจากนิติ *
    64985b3b515b80b78fe13bac = อุปกรณ์อยู่ระหว่างการผลิต *
    64985bbe515b80b78fe13bb6 = รออนุมัติงานซ่อม
    64985bc6515b80b78fe13bb8 = อนุมัติงานซ่อม
    64985bcf515b80b78fe13bba = ปิดงาน *

    64a7bf07785d4236922eaff5 = ไม่อนุมัติโดยหัวหน้างาน
    64a7bf23785d4236922eaff6 = อนุมัติโดยหัวหน้างาน
*/}

export const RepairHodWaitApproveJobList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    // let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    // let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);

    const { create, edit } = useNavigation();
    const [countInterval, setCountInterval] = useState(9);
    const [dataList, setDataList] = useState<any>([]);
    const [dataDetailList, setDataDetailList] = useState<any>([]);
    const [JobId, SetJobId] = useState<any>(undefined);
    const dataBlank:any = []
    const [keepCustomerName, setKeepCustomerName] = useState<any>();
    const [form] = Form.useForm();
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    const [selProp, setselProp] = useState<any>(undefined);
    const [nameSearch, setnameSearch] = useState<any>("");
    const [Unit, setUnit] = useState<any>(undefined);
    const [SearchStat, setSearchStat] = useState<any>(undefined);
    const [isLoading, setIsLoading] = useState<any>(true);
    const [page, setPage] = useState(1); // Default page
    const [pageSize, setPageSize] = useState(10); // Default page size


    const option_search:any = [
        {
            label: "เลื่อนนัดจากลูกค้า",
            value: "64985a67515b80b78fe13b96",
        },
        {
            label: "เลื่อนนัดจากนิติ",
            value: "64985ae5515b80b78fe13ba2",
        },
        {
            label: "อุปกรณ์อยู่ระหว่างการผลิต",
            value: "64985b3b515b80b78fe13bac",
        },
        {
            label: "ปิดงาน",
            value: "64985bcf515b80b78fe13bba",
        },
    ];

    const { selectProps: selProperty } = useSelect<any>({
        resource    : "properties",
        optionLabel : "property_name",
        metaData: {
            populate: '*',
            // locale:['th']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: !!prop_list || !!prop_id ? [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field : "id]",
                operator : prop_list ? "in" : "eq",
                value : prop_list ? prop_list : prop_id
            },
        ]:[
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
        ]
    });

    const { selectProps: SelUnit } = useSelect<any>({
        resource: "units",
        optionLabel: "unit_name",
        optionValue: "id",
        fetchSize: 10000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value : "N" 
            },
            {
                field       : "property][id]",
                operator    : "in",
                value       : selProp?.id
            },
        ]
    });

    function onSearchtable(data: any, mode:any, type:any){
        let newData:any = [];
        if(mode == "S"){

            // search job no
            newData = nameSearch !== "" ? dataDetailList?.filter((e:any)=> e?.job_obj[0].job_id?.toUpperCase().includes(String(nameSearch.toUpperCase()))) : newData;
            // search prop
            newData = selProp !== undefined ? dataDetailList?.filter((item:any)=> item?.job_obj[0].request_by?.unitDetail?.property_id == selProp?.id) : newData;
            // search unit
            newData = Unit !== undefined ? dataDetailList?.filter((item:any)=> item?.job_obj[0].request_by?.unitDetail?.unit_id == Unit?.id) : newData;
            // search stat
            newData = SearchStat !== undefined ? dataDetailList?.filter((item:any)=> item?.job_obj[0]?.status_id == SearchStat?.value) : newData;
            // search CustomerName
            newData = keepCustomerName !== undefined ? dataDetailList?.filter((item:any) => item?.job_obj[0]?.request_by?.attributes?.first_name?.toUpperCase().includes(String(keepCustomerName.toUpperCase())) ||
            item?.job_obj[0]?.request_by?.attributes?.last_name?.toUpperCase().includes(String(keepCustomerName.toUpperCase()))
            ) : newData

            setDataDetailList(newData);
        }else if(mode == "R"){
            form?.resetFields();
            setselProp(undefined);
            setUnit(undefined)
            refreshDataSource();
            setnameSearch("");
            setSearchStat(undefined)

        }
    }

    // useEffect(() => {
    //   console.log("prop_list",prop_list)
    //   console.log("prop_id",prop_id)
    // },)
    

    const getDataK = async ()=>{
        let dataItem:any
        // GET JOB DATA

        // GET JOB DETAIL DATA
        // let res_data = await axios.get(
        //     // majorCareUrl + `/majorcare/repairJobDetail/findRepairJobDetailByJobId`, 
        //     // majorCareUrl + `/majorcare/repairJobDetail/findRepairJobDetailByStat?stat=64985bbe515b80b78fe13bb6&page=1&limit=500`,
        //        majorCareUrl + `/majorcare/repairJobDetail/findMjcJobCount?stat=64985bbe515b80b78fe13bb6&page=1&limit=500&mode=2`,
        //     { headers: {  } }).then((res) => {
        //         let dataLoad: any = res?.data;
        //         console.log('dataLoadWait ', dataLoad)
        //         // console.log('prop_id', prop_id)

        //         if(prop_list !== undefined && prop_id == null){
        //             //console.log('case 1')
        //             dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.job_obj[0]?.request_by?.unitDetail?.property_id));
        //             console.log('case 1_data',dataLoad)
        //         }
        //         if(prop_id !== null && !prop_list){
        //             //console.log('case 2')
        //             dataLoad = dataLoad?.filter((e:any)=> e.job_obj[0]?.request_by?.unitDetail?.property_id == Number(prop_id))
        //             console.log('case 2_data',dataLoad)
        //         }
        //         if (prop_id !== null && !!prop_list) {
        //             dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.job_obj[0]?.request_by?.unitDetail?.property_id));
        //             console.log('case 3_data',dataLoad)
        //             dataItem = dataLoad
        //         }

        //         //console.log('dataLoad2 ', dataLoad)

        //         // รอหัวหน้าอนุมัติงานซ่อม เห็นรายการทั้งหมดตามสิทธิ์ของ Property ที่ตั้งไว้ แสดงสถานะรายการที่รอหัวหน้าอนุมัติ ดูจากรายการที่  hod_approve: true สามารถ edit รายการได้
        //         // ====================================================================================================================================
        //         // const xx = dataLoad.filter((e:any) => (e.job_obj[0]?.status_id === "64985bbe515b80b78fe13bb6") && e.job_obj[0].hod_approve === true );
        //         // const xx = dataLoad.filter((e:any) => (e.job_obj[0].hod_approve === true ) );
        //         // console.log('xx', xx)
        //         // ====================================================================================================================================


        //         // let xx = dataLoad.filter((e:any)=> e.job_obj.length > 0 && e.job_obj[0]?.status_id == "649859e7515b80b78fe13b86" || e.job_obj[0]?.status_id == "64985a67515b80b78fe13b96" || e.job_obj[0]?.status_id == "64985ae5515b80b78fe13ba2" ) 
        //         // let xx = dataLoad.filter((e:any)=> e.job_obj.length > 0 && e.job_obj[0]?.status_id == "649859e7515b80b78fe13b86") 
        //         // let xx = dataLoad.filter((e:any)=> e.job_obj[0]?.status_id == "649859e7515b80b78fe13b86" ) 

        //         // for (let index = 0; index < dataLoad.length; index++) {
        //         //     dataDetailList.push(dataLoad[index]);
        //         // }

        //         // dataDetailList.reverse()
        //         // setIsLoading(false)
                
        //     });
        
            let res_dataa = await axios.get(
                // majorCareUrl + `/majorcare/repairJobDetail/findRepairJobDetailByStat?stat=64985bbe515b80b78fe13bb6&page=1&limit=500`,
                //majorCareUrl + `/majorcare/repairJobDetail/findRepairJobDetailByStat?stat=64985a67515b80b78fe13b96,64985ae5515b80b78fe13ba2,64985b3b515b80b78fe13bac,64985bcf515b80b78fe13bba&page=1&limit=5000`,
                // API_URL + `/api/repair-settings/findRepairJobDetailByStat?stat=64985a67515b80b78fe13b96,64985ae5515b80b78fe13ba2,64985b3b515b80b78fe13bac,64985bcf515b80b78fe13bba&page=1&limit=5000`,
                majorCareUrl + `/majorcare/repairJobDetail/findRepairJobDetailByQuery?job_obj.status_id=64985a67515b80b78fe13b96,64985ae5515b80b78fe13ba2,64985b3b515b80b78fe13bac,64985bcf515b80b78fe13bba&job_obj.hod_approve=true&page=${page}&limit=${pageSize}`,
                { headers: { Authorization: `Bearer ${TOKEN_KEY}` } }).then((res) => {
                //{ headers: {  } }).then((res) => {
                    let dataLoadTwo: any = res?.data;
                    // console.log("dataLoadTwo",dataLoadTwo)

                    dataLoadTwo = dataLoadTwo.filter((item:any) => item.job_obj[0].hod_approve === true );
                    dataItem = dataLoadTwo

    
                    if(prop_list !== undefined && prop_id == null){
                        console.log('case 1')
                        dataLoadTwo = dataLoadTwo.filter((item:any) => (prop_list?.includes(item.job_obj[0]?.request_by?.unitDetail?.property_id)) && item.job_obj[0].hod_approve === true );
                        dataItem = dataLoadTwo
                    }
                    if(prop_id !== null && !prop_list){
                        console.log('case 2')
                        dataLoadTwo = dataLoadTwo?.filter((e:any)=> (e.job_obj[0]?.request_by?.unitDetail?.property_id == Number(prop_id)) && e.job_obj[0].hod_approve === true )
                        dataItem = dataLoadTwo
                    }
                    if (prop_id !== null && !!prop_list) {
                        console.log('case 3')
                        dataLoadTwo = dataLoadTwo.filter((item:any) => (prop_list?.includes(item.job_obj[0]?.request_by?.unitDetail?.property_id)) && item.job_obj[0].hod_approve === true);
                        dataItem = dataLoadTwo
                    }
    
                    // let dataNewSum = []
                    // for (let index = 0; index < dataItem?.length; index++) {
                    //     let keepSum = dataLoadTwo.filter((e:any) => e?._id == dataItem[index]?._id)
                    //     for (let ix = 0; ix < keepSum.length; ix++) {
                    //         if (keepSum.length !== 0) {
                    //             dataNewSum.push(keepSum[ix])
                    //         }
                    //     }
                    // }

                    // if (!!dataNewSum && dataNewSum !== null) {
                    //     dataNewSum.reverse()
                    //     setDataDetailList(dataNewSum)
                    // }else{
                    //     setDataDetailList(dataLoadTwo)
                    // }
                    if (!!dataItem && dataItem !== null) {
                        dataItem.reverse()
                        console.log('dataItem ', dataItem)
                        setDataDetailList(dataItem)
                    }else{
                        console.log('dataLoadTwo ', dataLoadTwo)
                        setDataDetailList(dataLoadTwo)
                    }
                    //dataDetailList.reverse()
                    setIsLoading(false)
                }); 
    }

    //console.log("keepFillter",dataItem)
    //     let res_data = await axios.get(
    //         majorCareUrl + `/majorcare/repairJobDetail/findRepairJobDetailByStat?stat=64985bbe515b80b78fe13bb6&page=1&limit=500`,
    //         { headers: {  } }).then((res) => {
    //             let dataLoad: any = res?.data;
    //             //console.log('dataLoadWait ', dataLoad)
    //             // console.log('prop_id', prop_id)

    //             if(prop_list !== undefined && prop_id == null){
    //                 //console.log('case 1')
    //                 dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.job_obj[0]?.request_by?.unitDetail?.property_id));
    //                 //console.log('case 1_data',dataLoad)
    //             }
    //             if(prop_id !== null && !prop_list){
    //                 //console.log('case 2')
    //                 dataLoad = dataLoad?.filter((e:any)=> e.job_obj[0]?.request_by?.unitDetail?.property_id == Number(prop_id))
    //                 //console.log('case 2_data',dataLoad)
    //             }
    //             if (prop_id !== null && !!prop_list) {
    //                 dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.job_obj[0]?.request_by?.unitDetail?.property_id));
    //                 //console.log('case 3_data',dataLoad)
    //             }
    //             console.log("keepFillterDataLoad",dataLoad)

    //             // for (let index = 0; index < dataItem.length; index++) {
    //             //     let keepSum = dataLoad.filter((e:any) => e?._id == dataItem[index]?._id)
    //             //     for (let ix = 0; ix < keepSum.length; ix++) {
    //             //         if (keepSum.length !== 0) {
    //             //             dataDetailList.push(keepSum[ix])
    //             //         }
    //             //     }
    //             // }
    //         }); 
    // }

    // console.log('dataList', dataList)
    console.log('dataDetailList', dataDetailList)
    const { confirm } = Modal;
    const successNoti = (stat:any, msg:any) => {
        notification.success({
            // message: stat + ' : ' + msg.TH,
            message: msg,
            // description: msg,
            duration: 3,
        });
    };

    useEffect(() => {
        if (countInterval <= 1) {
            const interval = setInterval(() => {
                setCountInterval(prev => prev + 1);
                if(countInterval === 1){
                    getDataK();
                }
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [countInterval])

    useEffect(() => {
        getDataK();
    }, [page,pageSize]);

    async function updateStatus(id:any, val:any){
        // console.log('id', id)
        let data = {
            "is_active": val,
        }
        let res_update = await axios.put(
            majorCareUrl + `/majorcare/updateHomecheckjob?id=` + id , data ,
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                //console.log('dataLoad Check', dataLoad)
            }
        );
        refreshDataSource()
    }

    const [refreshFlag, setRefreshFlag] = useState(false);

    const clearDataSource = () => {
        setDataList([])
        setDataDetailList([])
    }

    const refreshDataSource = () => {
        clearDataSource()
        setCountInterval(0)
        setRefreshFlag(!refreshFlag);
    };

    function showDelConfirm(id:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Delete Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to Delete this data.',
            okText  : 'Confirm',
            onOk() {
                updateStatus(id, false);
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 2000); // orginal = 1000
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        // console.log('id', id)
        confirm({
            title   : 'Restore Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to restore this data.',
            okText  : 'Confirm',
            onOk() {
                updateStatus(id, true);
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 2000); // orginal = 1000
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }
    
    const [JobIdToMakeAppointment, SetJobIdToMakeAppointment] = useState<any>([]);
    const [BtnDisable, SetBtnDisable] = useState<any>(true);

    const onChangeCheckJob = (e:any) => {
        // console.log('checked = ', e.target.checked);
        console.log('checked = ', e.target.checked);
        console.log('value = ', e.target.value);
        // setCheckedEmail(e.target.checked);

        if(e.target.checked){
            // add job's id to array
            console.log('add jobs id to array')
            JobIdToMakeAppointment.push(e.target.value)
        }else{
            // remove job's id to array
            console.log('remove jobs id to array')
            const index = JobIdToMakeAppointment.indexOf(e.target.value);
            if (index > -1) { // only splice array when item is found
                JobIdToMakeAppointment.splice(index, 1); // 2nd parameter means remove one item only
            }
        }

        if(JobIdToMakeAppointment.length > 0){
            SetBtnDisable(false)
        }else{
            SetBtnDisable(true)
        }
        console.log('JobIdToMakeAppointment', JobIdToMakeAppointment)
        
    };

    const columns:any = [
        // {
        //     title: 'SELECT',
        //     dataIndex: 'select_na',
        //     key: 'select_na',
        //     width: 80,
        //     // sorter: (a:any, b:any) => a.job_id.localeCompare(b.job_id),
        //     render: (value:any, record:any) => {
        //         return (
        //             <>
        //                 <Checkbox 
        //                     key={record.job_obj[0]._id} 
        //                     value={record.job_obj[0]._id}
        //                     onChange={(e:any) => onChangeCheckJob(e)}
        //                 >
        //                 </Checkbox>
        //             </>
        //         );
        //     },
        // },
        {
            title: 'JOB NO.',
            dataIndex: 'job_id',
            key: 'job_id',
            width: 150,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.job_id < b?.job_obj[0]?.job_id) {return -1}
                if (a?.job_obj[0]?.job_id > b?.job_obj[0]?.job_id) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].job_id !== null ? record.job_obj[0].job_id : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'PROPERTY',
            // dataIndex: 'name',
            key: 'property',
            width: 160,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.request_by?.unitDetail?.property_name < b?.job_obj[0]?.request_by?.unitDetail?.property_name) {return -1}
                if (a?.job_obj[0]?.request_by?.unitDetail?.property_name > b?.job_obj[0]?.request_by?.unitDetail?.property_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].request_by ? record.job_obj[0].request_by.unitDetail.property_name : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'UNIT NO.',
            dataIndex: 'unit',
            key: 'unit',
            width: 150,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.request_by?.unitDetail?.unit_name < b?.job_obj[0]?.request_by?.unitDetail?.unit_name) {return -1}
                if (a?.job_obj[0]?.request_by?.unitDetail?.unit_name > b?.job_obj[0]?.request_by?.unitDetail?.unit_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].request_by ? record.job_obj[0].request_by.unitDetail.unit_name : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.createBy.first_name.localeCompare(b.createBy.first_name),
        },
        {
            title: 'รายละเอียด',
            dataIndex: 'details',
            key: 'details',
            width: 280,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.zone_obj[0]?.zone_name < b?.zone_obj[0]?.zone_name) {return -1}
                if (a?.zone_obj[0]?.zone_name > b?.zone_obj[0]?.zone_name) {return 1}
                if (a?.category_obj[0].category_name < b?.category_obj[0].category_name) {return -1}
                if (a?.category_obj[0].category_name > b?.category_obj[0].category_name) {return 1}
                if (a?.category_detail_obj[0].category_detail_name < b?.category_detail_obj[0].category_detail_name) {return -1}
                if (a?.category_detail_obj[0].category_detail_name > b?.category_detail_obj[0].category_detail_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.zone_obj[0] !== undefined ? record.zone_obj[0].zone_name : '-': '-'} - {record ? record.category_obj[0] !== undefined ? record.category_obj[0].category_name : '-': '-'} - {record ? record.category_detail_obj[0] !== undefined ? record.category_detail_obj[0].category_detail_name : '-': '-'} </div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.createBy.first_name.localeCompare(b.createBy.first_name),
        },
        {
            title: 'CUSTOMER NAME',
            // dataIndex: 'updateBy',
            key: 'cus_name',
            width: 200,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.request_by?.attributes?.first_name < b?.job_obj[0]?.request_by?.attributes?.first_name) {return -1}
                if (a?.job_obj[0]?.request_by?.attributes?.first_name > b?.job_obj[0]?.request_by?.attributes?.first_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].request_by.attributes !== undefined ? record.job_obj[0].request_by.attributes.first_name + " " + record.job_obj[0].request_by.attributes.last_name  : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: 'TRANSFERRED DATE',
            dataIndex: 'transfer_date',
            width: 180,
            key: 'transfer_date',
            align: 'center',
            sorter: (a:any, b:any) => {
                // if (a?.job_obj[0]?.request_by?.unitDetail < b?.job_obj[0]?.request_by?.unitDetail) {return -1}
                // if (a?.job_obj[0]?.request_by?.unitDetail > b?.job_obj[0]?.request_by?.unitDetail) {return 1}
                if (a?.lscUnit?.owner_in_date < b?.lscUnit?.owner_in_date) {return -1}
                if (a?.lscUnit?.owner_in_date > b?.lscUnit?.owner_in_date) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                if(record?.job_obj.length > 0){
                    //let dateString = record?.job_obj[0]?.request_by?.unitDetail?.transfer_date
                    let dateString = record?.lscUnit?.owner_in_date
                    if(dateString){
                        //const hasT000000Format = dateString.includes("T00:00:00.000") || dateString.includes("T17:00:00.000") || dateString.includes("T07:00:00.000") ;
                        const hasT000000Format = dateString.includes("T");
                        if (hasT000000Format) {
                          const formattedDate = dayjs(dateString).format('DD/MM/YY');
                          return formattedDate;
                        }else{
                            return dateString
                        }
                    }else{
                        return "-"
                    }
                }else{
                    return (<div>-</div>)
                }
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: 'CREATED DATE',
            dataIndex: 'create_date',
            key: 'create_date',
            width: 160,
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.create_date < b?.job_obj[0]?.create_date) {return -1}
                if (a?.job_obj[0]?.create_date > b?.job_obj[0]?.create_date) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].create_date !== null ? dayjs(record.job_obj[0].create_date).format("DD/MM/YY HH:mm") : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.create_date.localeCompare(b.create_date),
        },
        {
            title: 'APPOINTMENT DATE',
            dataIndex: 'appoint_start',
            key: 'appoint_start',
            width: 180,
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.appoint_start < b?.job_obj[0]?.appoint_start) {return -1}
                if (a?.job_obj[0]?.appoint_start > b?.job_obj[0]?.appoint_start) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
  <div>
                            {record ? record?.job_obj?.[0]?.appoint_start != null
                                ? dayjs(record.job_obj[0].appoint_start).format('DD/MM/YYYY HH:mm')
                                : '-' : '-'}
                        </div>                        </>
                );
            },
            // sorter: (a:any, b:any) => a.create_date.localeCompare(b.create_date),
        },
        {
            title: 'ช่างผู้รับผิดชอบ',
            // dataIndex: 'inspec_name',
            key: 'inspec_name',
            width: 200,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.assign?.attributes?.user_profile?.data?.attributes?.first_name < b?.job_obj[0]?.assign?.attributes?.user_profile?.data?.attributes?.first_name) {return -1}
                if (a?.job_obj[0]?.assign?.attributes?.user_profile?.data?.attributes?.first_name > b?.job_obj[0]?.assign?.attributes?.user_profile?.data?.attributes?.first_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                //console.log('record', record)
                return (
                    <>
                        <div>
                            {
                                record ?
                                    record.job_obj[0]?.assign !== null ?
                                        record.job_obj[0]?.assign?.attributes?.user_profile ?
                                            record.job_obj[0]?.assign?.attributes?.user_profile?.data?.attributes?.first_name + ' ' +  record.job_obj[0]?.assign?.attributes?.user_profile?.data?.attributes?.last_name
                                        : '-'
                                    : '-'
                                : '-'
                            }
                        </div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.create_date.localeCompare(b.create_date),
        },
        {
            title: 'วันส่งอนุมัติ',
            dataIndex: 'send_approve_date',
            key: 'send_approve_date',
            width: 150,
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.job_trails_obj[0]?.create_date < b?.job_trails_obj[0]?.create_date) {return -1}
                if (a?.job_trails_obj[0]?.create_date > b?.job_trails_obj[0]?.create_date) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                // console.log('record', record)
                // console.log('record.job_obj[0].job_trails_obj', record.job_trails_obj)
                return (
                    <>
                        <div>{record ? record.job_trails_obj !== null ? dayjs(record.job_trails_obj[record.job_trails_obj.length - 1]?.create_date).format("YYYY-MM-DD HH:mm") : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.create_date.localeCompare(b.create_date),
        },
        {
            title: 'ผ่านมาแล้วกี่วัน',
            dataIndex: 'day_count',
            width: 160,
            key: 'day_count',
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.create_date < b?.job_obj[0]?.create_date) {return -1}
                if (a?.job_obj[0]?.create_date > b?.job_obj[0]?.create_date) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                const date1 = dayjs(record.job_obj[0].create_date);
                const date2 = dayjs();

                let hours = date2.diff(date1, 'hours');
                const days = Math.floor(hours / 24);
                // hours = hours - (days * 24);
                //console.log('Days: ', days);
                // console.log('Hours: ', hours);
                return (
                    <>
                        <div> { days } </div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.create_date.localeCompare(b.create_date),
        },
        {
            title: 'CREATED BY',
            dataIndex: 'create_by',
            key: 'create_by',
            width: 150,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.create_by?.first_name < b?.job_obj[0]?.create_by?.first_name) {return -1}
                if (a?.job_obj[0]?.create_by?.first_name > b?.job_obj[0]?.create_by?.first_name) {return 1}
                return (0)
            },
            render: (createBy:any, record:any) => {
                return (
                    <>
                        <div >{record ? record?.job_obj[0]?.create_by ? record?.job_obj[0]?.create_by?.first_name  : "-" : "-"} {record?.job_obj[0]?.create_by?.last_name ? record?.job_obj[0]?.create_by?.last_name : ""}</div>
                    </>
                );
            },
        },
        {
            title: 'UPDATE BY',
            dataIndex: 'update_by',
            key: 'update_by',
            width: 150,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.update_by?.first_name < b?.job_obj[0]?.update_by?.first_name) {return -1}
                if (a?.job_obj[0]?.update_by?.first_name > b?.job_obj[0]?.update_by?.first_name) {return 1}
                return (0)
            },
            render: (updateBy:any, record:any) => {
                return (
                    <>
                        <div >{record ? record?.job_obj[0]?.update_by ? record?.job_obj[0]?.update_by?.first_name  : "-" : "-"} {record?.job_obj[0]?.update_by?.last_name ? record?.job_obj[0]?.update_by?.last_name : ""}</div>
                    </>
                );
            },
        },
        {
            title: 'Last Update',
            dataIndex: 'update_date',
            key: 'update_date',
            width: 150,
            align: 'center',
            sorter: (a:any, b:any) => a.job_obj[0]?.update_date.localeCompare(b.job_obj[0]?.update_date),
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].update_date !== null ? dayjs(record.job_obj[0].update_date).format("DD/MM/YY HH:mm") : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            key: 'status',
            width: 180,
            fixed: 'right',
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.status_name < b?.job_obj[0]?.status_name) {return -1}
                if (a?.job_obj[0]?.status_name > b?.job_obj[0]?.status_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>
                            <Tag className="tag_mjc_stat_wait_inspect">{record.job_obj[0].status_name}</Tag>
                        </div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: 'SLA',
            dataIndex: 'sla',
            key: 'sla',
            width: 100,
            fixed: 'right',
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.sla?.sla < b?.job_obj[0]?.sla?.sla) {return -1}
                if (a?.job_obj[0]?.sla?.sla > b?.job_obj[0]?.sla?.sla) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        {
                            record.job_obj[0].sla != undefined ? 
                                record.job_obj[0].sla.sla == "Y" ? 
                                    <div style={{color:'green'}}>On Time</div>
                                : record.job_obj[0].sla.sla == "N" ? 
                                        <div style={{color:'red'}}>Over Time</div>
                                    :
                                        '-'
                            : '-'
                        }
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: 'ACTION',
            dataIndex: '',
            key: 'action',
            fixed: 'right',
            width: 120,
            align: 'center',
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        {
                            record.job_obj[0].is_active === true ? 
                                <>
                                     <EditButton
                                        hideText
                                        size="small"
                                        style={{marginRight:'5px'}}
                                        onClick={() => edit("repair-hod-view-job", record._id + "_" + record.job_id )}
                                        // disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                    />
                                    {/* <Button 
                                        size="small" 
                                        title="Delete" 
                                        icon={<Icons.DeleteOutlined />} 
                                        onClick={() => { showDelConfirm(record._id) }} 
                                        danger
                                    ></Button> */}
                                    
                                </>
                            :
                                <>
                                    <Button 
                                        size="small" 
                                        title="Active" 
                                        icon={<Icons.RetweetOutlined />} 
                                        onClick={() => { showUnlockConfirm(record._id) }} 
                                    ></Button>
                                </>
                        }
                    </>
                );
            },
        },
    ];

    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        if(pagination?.current){
            setPage(pagination?.current)
        }
        if(pagination?.pageSize){
            setPageSize(pagination?.pageSize)
        }
    };
    
    return <>
        <List title="">

            <Row gutter={12}>
                <Col span={22}>
                    <Form layout="vertical" 
                        // {...formProps} 
                        onFinish={(values:any) => {}}
                        form = {form} 
                    >
                        <Row gutter={20} style={{width: "100%"}}>
                            <Col span={4} className="gutter-row">
                                <Form.Item
                                    name="property"
                                    label="Search"
                                >
                                    <Select 
                                        placeholder="Property" 
                                        {...selProperty} allowClear 
                                        onChange={(value:any, record:any) => { 
                                            setselProp({id:value, label: record?.label})
                                        }}
                                        onClear={()=> {
                                            setselProp(undefined);
                                            form.resetFields(["property"]);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4} className="gutter-row">
                                <Form.Item
                                    // label={t("policy.policy_code")}
                                    label=" "
                                    name="unit"
                                >
                                    <Select {...SelUnit}
                                        placeholder="Select unit" 
                                        size="middle" 
                                        style={{borderRadius: 20, width: '100%', position:'relative'}} 
                                        onChange={(value:any, record:any) => { 
                                            setUnit({id:value, label: record?.label})
                                        }}
                                        onClear={()=> {
                                            setUnit(undefined);
                                            form.resetFields(["unit"]);
                                        }}
                                        allowClear
                                        showSearch
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4} className="gutter-row">
                                <Form.Item name="template_name" label=" ">
                                    <Input 
                                        placeholder="Search Job No." 
                                        onChange={(e) => { if(e.target.value.length > 0){ setnameSearch(e?.target?.value)}else{setnameSearch("")}}} 
                                        // suffix={<Icons.SearchOutlined/>} 
                                    allowClear/>
                                </Form.Item>
                            </Col>

                            <Col span={4} className="gutter-row">
                                <Form.Item name="detail" label=" ">
                                    <Input 
                                        placeholder="Search Customer name" 
                                        onChange={(e) => setKeepCustomerName(e.target.value)} 
                                        // suffix={<Icons.SearchOutlined/>} 
                                    allowClear/>
                                </Form.Item>
                            </Col>

                            {/* <Col span={4} className="gutter-row">
                                <Form.Item name="stat_job_search" label=" ">
                                    <Select 
                                        placeholder="Status"
                                        style={{ width: '100%' }}
                                        onSelect={(value: any, record:any) => {
                                            console.log('setSearchStat', record)
                                            setSearchStat(record);
                                        }}
                                        options={option_search}
                                        allowClear
                                    />
                                </Form.Item>
                            </Col> */}
                           
                            <Col span={5} className="gutter-row">
                                <Row gutter={24}>
                                    <Col span={12} className="gutter-row">
                                        <Form.Item label=" ">
                                            <Button type="primary" style={{borderRadius: "5px"}} onClick={()=> onSearchtable(dataDetailList,"S", "xxxxxx")}>
                                                <Icons.SearchOutlined /> Search
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} className="gutter-row">
                                        <Form.Item label=" ">
                                            <Button 
                                                onClick={() => {
                                                    onSearchtable(dataDetailList,"R", "xxxx")
                                                }} 
                                                style={{borderRadius: "5px"}}
                                                icon={<Icons.UndoOutlined />}
                                            >
                                                Reset
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>


            {/* <div>
                {
                    dataDetailList.length > 0 ?
                        <Table 
                            // dataSource={dataList} 
                            // dataSource={dataList.length > 0 ? dataList : dataBlank} 
                            dataSource={dataDetailList.length > 0 ? dataDetailList : dataBlank} 
                            columns={columns} 
                            onChange={onChange} 
                            loading={isLoading}
                            // pagination={{ size: "small", position: [ bottom] }} 
                            // pagination={{ size: "small", position: [ 'bottomRight'] }} 
                            pagination={{
                                size: "small",
                                position: ['bottomRight'],
                                // showTotal: showTotal, 
                                pageSizeOptions: ['10', '20', '50'], // Optional - Customize the available page sizes
                                showSizeChanger: true, // Optional - Show page size changer dropdown
                                // showQuickJumper: true, // Optional - Show quick jumper input
                                // pageSize: 10, // Optional - Default page size
                                pageSize: pageSize,
                                onChange: handlePageSizeChange,
                            }}
                            scroll={{ x: 2000 }}
                        />
                    :
                        <Table  
                            columns={columns} 
                            onChange={onChange} 
                            // pagination={{ size: "small", position: [ bottom] }} 
                            pagination={{ size: "small", position: [ 'bottomRight'] }} 
                        />
                }
            </div> */}

            <Table 
                dataSource={dataDetailList.length > 0 ? dataDetailList : dataBlank} 
                columns={columns} 
                onChange={onChange} 
                loading={isLoading}
                pagination={{
                    size: "small",
                    position: ['bottomRight'],
                    pageSizeOptions: ['10', '20', '50'], // Optional - Customize the available page sizes
                    showSizeChanger: true, // Optional - Show page size changer dropdown
                    pageSize: pageSize,
                    current: page
                }}
                scroll={{ x: 2800 }}
            />

            <Row style={{float:'right', paddingTop:'10px'}}>
                <Col span={24} >
                    {t("repair-stat.title")} {dataDetailList?.length}
                </Col>
            </Row>

            {/* <Row style={{marginTop:'2%'}}>
                {
                    JobIdToMakeAppointment.length > 0 ?
                        <Button 
                            style={{ color:"#ffffff", backgroundColor:"#167AD8", borderRadius: "10px"}}
                            onClick={() => edit("repair-inspec-send-to-cust", JobIdToMakeAppointment )}
                            disabled={BtnDisable}
                        >
                            ส่งให้ลูกค้าอนุมัติ
                        </Button>
                    :
                        <Button 
                            style={{ color:"#ffffff", backgroundColor: "#b3b3b3", borderRadius: "10px"}}
                            disabled={BtnDisable}
                        >
                            ส่งให้ลูกค้าอนุมัติ
                        </Button>
                }
            </Row> */}
        </List>
    </>
}