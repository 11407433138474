/* eslint-disable */
import { Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField,Upload, useTable, Input, Button, CreateButton, Tabs, Badge, EditButton, Tag, Select,Typography, useSelect, useModalForm, Radio, message, RcFile, UploadFile, Switch, Divider, TableProps, Icon, notification, ShowButton, Tooltip, useSimpleList } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useNavigation, useTranslate, useUpdate } from "@pankod/refine-core";
import axios from "axios";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { majorCareUrl } from "../../../constants";
import { CheckCircleOutlined, QuestionOutlined, WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";

{/* 
    MAIN STATUS

    649859ca515b80b78fe13b84 = รอดำเนินการ
    649859e7515b80b78fe13b86 = รับเรื่องแจ้งซ่อม
    649859f8515b80b78fe13b88 = นัด วัน/เวลา ประเมินหน้างาน
    64985a01515b80b78fe13b8a = เข้าประเมิน
    64985a0f515b80b78fe13b8c = เข้าประเมิน-เกินกำหนด
    64985a29515b80b78fe13b8e = ประเมินเสร็จ
    64985a35515b80b78fe13b90 = นัด วัน/เวลา เข้าซ่อม
    64985a3c515b80b78fe13b92 = เข้าซ่อม
    64985a47515b80b78fe13b94 = เข้าซ่อม-เกินกำหนด
    64985a67515b80b78fe13b96 = เลื่อนนัดจากลูกค้า *
    64985ae5515b80b78fe13ba2 = เลื่อนนัดจากนิติ *
    64985b3b515b80b78fe13bac = อุปกรณ์อยู่ระหว่างการผลิต *
    64985bbe515b80b78fe13bb6 = รออนุมัติงานซ่อม
    64985bc6515b80b78fe13bb8 = อนุมัติงานซ่อม
    64985bcf515b80b78fe13bba = ปิดงาน *
    64ad1075f3440e02a3156fa5 = อนุมัติใบเสนอราคา
    64ad1085f3440e02a3156fa6 = ส่งใบเสนอราคา
    64ad1091f3440e02a3156fa7 = ไม่อนุมัติใบเสนอราคา
*/}

{/* 
    SUB STATUS
    
    ******* main = เลื่อนนัดจากลูกค้า ******* option_cust_postpone
    64985a95515b80b78fe13b98 = ลูกค้าติดธุระส่วนตัว
    64985a9c515b80b78fe13b9a = ลูกค้าเป็นโควิด/กักตัว
    64985aaa515b80b78fe13b9c = ลูกค้า ไม่สะดวกนัดวันซ่อม เนื่องจากทำงาน
    64985ab9515b80b78fe13b9e = ลูกค้าไม่มีคนเฝ้าห้องให้ กรณีนัดซ่อมงาน ต่อเนื่องหลายวัน
    64985ac3515b80b78fe13ba0 = ไม่สามารถติดต่อลูกค้าได้

    ******* main = เลื่อนนัดจากนิติ ******* option_juristic_postpone
    64985b04515b80b78fe13ba4 = ผรม. แจ้งว่าไม่สามารถเข้าได้ตามกำหนด
    64985b0d515b80b78fe13ba6 = ผลิตภัณฑ์ที่อยู่ระหว่างผลิต ไม่สามารถเข้าติดตั้งได้
    64985b17515b80b78fe13ba8 = ทางโครงการไม่ให้เข้าแก้ไข ตามวันเวลา ที่แจ้ง
    64985b21515b80b78fe13baa = รอ Major อนุมัติ PO/PR

    ******* main = อุปกรณ์อยู่ระหว่างการผลิต ******* option_matt_in_manufacturing
    64985b5e515b80b78fe13bae = อุปกรณ์ สุขภัณฑ์
    64985b68515b80b78fe13bb0 = วัสดุตกแต่งห้องชุด เช่น กระเบื้อง, พื้นไม้, วอเปเปอร์
    64985b74515b80b78fe13bb2 = อุปกรณ์ที่ต้องสั่งผลิต เช่น เฟอร์นิเจอร์
    64985b80515b80b78fe13bb4 = อุปกรณ์ที่ต้องสั่งผลิต เช่น เครื่องใช้ไฟฟ้า

    ******* main = ปิดงาน ******* option_close_job
    64985be1515b80b78fe13bbc = ส่งงานเสร็จสมบูรณ์
    64985bec515b80b78fe13bbe = Customer เปิดงานซ้ำ
    64985bf6515b80b78fe13bc0 = Customer เปิดงานผิด
    64985c0c515b80b78fe13bc2 = Customer เปิดงานผิด-เจ้าหน้าที่เปิดงานใหม่ให้ลูกค้า
    64985c15515b80b78fe13bc4 = ให้คำแนะนำ เพื่อจัดจ้าง
    64985c22515b80b78fe13bc6 = งานที่ไม่อยู่ในเงื่อนไขรับประกัน
    64985c2d515b80b78fe13bc8 = รายละเอียดไม่ตรงกับรายการรับประกัน
    64985c38515b80b78fe13bca = ลูกค้าเลื่อนซ่อม เกิน SLA
    64985c42515b80b78fe13bcc = รอสั่งของ
    64985c4a515b80b78fe13bce = งานซ่อมที่เกิดจากการใช้งาน
    64985c56515b80b78fe13bd0 = งานเคลมประกันภัยอาคาร
    64ab8769785d4236922eaff8 = ลูกค้าปฏิเสธงานแจ้งซ่อม

*/}

export const MjcRepairMdpcNewJob: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    // let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    let data_multi_property = Identity_data?.prop_list?.length > 1 ? Identity_data?.prop_list.map((property:any) => property.id) : null

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);


    // console.log('prop_id', prop_id)
    // console.log('prop_list', prop_list)


    const { create, edit } = useNavigation();
    const [countInterval, setCountInterval] = useState(9);
    const [dataList, setDataList] = useState<any>([]);
    const [dataDetailList, setDataDetailList] = useState<any>([]);
    const [SearchStat, setSearchStat] = useState<any>(undefined);
    const [countNewJob, setCountNewJob] = useState<any>(undefined);
    const [keepCustomerName, setKeepCustomerName] = useState<any>()
    const [keepDataDetail, setKeepDataDetail] = useState<any>(undefined);

    const dataBlank:any = []

    const [form] = Form.useForm();
    const [selProp, setselProp] = useState<any>(undefined);
    const [nameSearch, setnameSearch] = useState<any>("");
    const [Unit, setUnit] = useState<any>(undefined);
    const [IsLoading, setIsLoading] = useState<any>(true);
    const [IsRefetch2, setIsRefetch2] = useState<any>(true);


    const { selectProps: selProperty } = useSelect<any>({
        resource    : "properties",
        optionLabel : "property_name",
        metaData: {
            populate: '*',
            // locale:['th']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: !!prop_list || !!prop_id ? [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            // {
            //     field : "id]",
            //     operator : data_property ? "eq" : "nin",
            //     value : data_property ? data_property : null
            // },
            {
                field : "id]",
                operator : prop_list ? "in" : "eq",
                value : prop_list ? prop_list : prop_id
            },
        ]:[
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
        ]
    });

    const { selectProps: SelUnit } = useSelect<any>({
        resource: "units",
        optionLabel: "unit_name",
        optionValue: "id",
        fetchSize: 10000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value : "N" 
            },
            {
                field       : "property][id]",
                operator    : "in",
                value       : selProp?.id
            },
        ]
    });

    const option_search:any = [
        {
            label: "รอดำเนินการ", 
            value: "649859ca515b80b78fe13b84",
        },
    ];

    function onSearchtable(data: any, mode:any, type:any){
        let newData:any = [];
        if(mode == "S"){
           
            if (!!nameSearch) {
                newData = dataDetailList?.filter((e:any)=> e?.job_obj[0].job_id?.includes(String(nameSearch)));
                setDataDetailList([])

                setDataDetailList(newData);
                setCountNewJob(newData);

            }
            if (!!selProp) {
                console.log("selProp")
                newData = dataDetailList?.filter((item:any)=> item?.job_obj[0].request_by?.unitDetail?.property_id == selProp?.id)
                setDataDetailList([])

                setDataDetailList(newData);
                setCountNewJob(newData);

            }
            if (!!Unit) {
                console.log("Unit")
                newData = dataDetailList?.filter((item:any)=> item?.job_obj[0].request_by?.unitDetail?.unit_id == Unit?.id)
                
                setDataDetailList([])

                setDataDetailList(newData);
                setCountNewJob(newData);

            }
            if (!!keepCustomerName) {
                newData = dataDetailList?.filter((item:any) => item?.job_obj[0]?.request_by?.attributes?.first_name?.includes(String(keepCustomerName.split(" ",1))) ||
                item?.job_obj[0]?.request_by?.attributes?.last_name?.includes(String(keepCustomerName.split(" ",2)))
                )
                setDataDetailList([])

                setDataDetailList(newData);
                setCountNewJob(newData);
            
            }
            if (!!nameSearch && !!keepCustomerName) {
                console.log("nameSearch && keepCustomerName")
                newData = dataDetailList?.filter((e:any)=> e?.job_obj[0].job_id?.includes(String(nameSearch)) && e?.job_obj[0]?.request_by?.attributes?.first_name?.includes(String(keepCustomerName)) ||
                e?.job_obj[0]?.request_by?.attributes?.last_name?.includes(String(keepCustomerName)));
                setDataDetailList([])

                setDataDetailList(newData);
                setCountNewJob(newData);

            }
            if (!!selProp && !!Unit) {
                console.log("selProp && Unit")
                newData = dataDetailList?.filter((item:any)=> item?.job_obj[0].request_by?.unitDetail?.property_id == selProp?.id && item?.job_obj[0].request_by?.unitDetail?.unit_id == Unit?.id)
                setDataDetailList([])

                setDataDetailList(newData);
                setCountNewJob(newData);
            }
            if (!!selProp && !!Unit && !!nameSearch) {
                console.log("selProp && Unit && Job no")
                newData = dataDetailList?.filter((item:any)=> item?.job_obj[0].request_by?.unitDetail?.property_id == selProp?.id && 
                item?.job_obj[0].request_by?.unitDetail?.unit_id == Unit?.id &&
                item?.job_obj[0].job_id?.includes(String(nameSearch))
                )
                setDataDetailList([])

                setDataDetailList(newData);
                setCountNewJob(newData);
            }
            if (!!selProp && !!Unit && !!nameSearch && !!keepCustomerName) {
                console.log("selProp && Unit && Job no && keepCustomerName")
                newData = dataDetailList?.filter((item:any)=> item?.job_obj[0].request_by?.unitDetail?.property_id == selProp?.id && 
                item?.job_obj[0].request_by?.unitDetail?.unit_id == Unit?.id &&
                item?.job_obj[0].job_id?.includes(String(nameSearch)) &&
                item?.job_obj[0]?.request_by?.attributes?.first_name?.includes(String(keepCustomerName.split(" ",1))) ||
                item?.job_obj[0]?.request_by?.attributes?.last_name?.includes(String(keepCustomerName.split(" ",2)))
                )
                setDataDetailList([])

                setDataDetailList(newData);
                setCountNewJob(newData);
            }

            // search detail
            newData = keepDataDetail !== undefined ? dataDetailList?.filter((item:any) => item?.zone_obj[0]?.zone_name?.includes(String(keepDataDetail)) ||
            item?.category_obj[0]?.category_name?.includes(String(keepDataDetail)) ||
            item?.category_detail_obj[0]?.category_detail_name.includes(String(keepDataDetail))
            ) : newData

            // // search job no
            // newData = nameSearch !== "" ? dataDetailList?.filter((e:any)=> e?.job_obj[0].job_id?.includes(String(nameSearch))) : newData;
            // // search prop
            // newData = selProp !== undefined ? dataDetailList?.filter((item:any)=> item?.job_obj[0].request_by?.unitDetail?.property_id == selProp?.id) : newData;
            // console.log("selProp",selProp)
            // console.log("selPropNew",newData)
            // // search unit
            // newData = Unit !== undefined ? dataDetailList?.filter((item:any)=> item?.job_obj[0].request_by?.unitDetail?.unit_id == Unit?.id) : newData;
            // console.log("Unit",Unit)
            // // search stat
            // newData = SearchStat !== undefined ? dataDetailList?.filter((item:any)=> item?.job_obj[0]?.status_id == SearchStat?.value) : newData;
            // // search CustomerName
            // newData = keepCustomerName !== undefined ? dataDetailList?.filter((item:any) => item?.job_obj[0]?.request_by?.attributes?.first_name?.includes(String(keepCustomerName)) ||
            // item?.job_obj[0]?.request_by?.attributes?.last_name?.includes(String(keepCustomerName))
            // ) : newData

            // setDataDetailList([])

            // setDataDetailList(newData);
            // setCountNewJob(newData);
        }else if(mode == "R"){
            form?.resetFields();
            setselProp(undefined);
            setUnit(undefined)
            setKeepCustomerName(undefined)
            setKeepDataDetail(undefined);
            refreshDataSource();
            setSearchStat(undefined)
            setnameSearch("");
        }
    }

    const getDataK = async ()=>{

        // GET JOB DETAIL DATA
        let res_detail_data = await axios.get(
            // majorCareUrl + `/majorcare/repairJobDetailMdpc/findRepairJobDetailByJobId`, 
            majorCareUrl + `/majorcare/repairJobDetailMdpc/findRepairJobDetailByStatMDPC?stat=649859ca515b80b78fe13b84&page=1&limit=500`, 

            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                // console.log('dataLoad Detail', dataLoad)
                // console.log('prop_id', prop_id)

                // if(prop_id !== null){
                //     dataLoad = dataLoad?.filter((e:any)=> e.property_obj[0]?.property_id == Number(prop_id))
                // }

                dataLoad?.reverse();

                if(prop_list !== undefined){
                    console.log('case 1')
                    dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.job_obj[0]?.request_by?.unitDetail?.property_id));
                }else if(prop_id !== null){
                    console.log('case 2')
                    dataLoad = dataLoad?.filter((e:any)=> e.job_obj[0]?.request_by?.unitDetail?.property_id == Number(prop_id))
                    console.log("dataLoad",dataLoad)
                }

                // let xx = dataLoad.filter((e:any)=> e.job_obj.length > 0 && e.job_obj[0]?.status_id == "649859ca515b80b78fe13b84" &&  e.job_obj[0].is_delegate === false ) // รอดำเนินการ
                let xx = dataLoad.filter((e:any)=> e.job_obj.length > 0 && e.job_obj[0].is_delegate === false )
                console.log("xx",xx)
                for (let index = 0; index < xx.length; index++) {
                    dataDetailList.push(xx[index]);
                }
                setIsLoading(false)
                setCountNewJob(xx)
            });
    }
    // console.log('dataList', dataList)
    // console.log('dataDetailList', dataDetailList)
    const { confirm } = Modal;
    const successNoti = (stat:any, msg:any) => {
        // notification.success({
        //     // message: stat + ' : ' + msg.TH,
        //     message: msg,
        //     // description: msg,
        //     duration: 3,
        // });

        notification['success']({
            message: "Successful",
            description:
              'Successfully updated',
        });
    };

    function showAcknowledge(id:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'ยืนยันการรับเรื่องแจ้งซ่อม', 
            icon    : <QuestionOutlined />,
            // content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                // vCurDelStatus.deleteStatus = 'Y'
                updateAcknowledge(id, "ยืนยันการรับเรื่องแจ้งซ่อม");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    async function updateAcknowledge(id:any, event:any){
        console.log('id : ', id)

        // ================================= CAL SLA ======================
        // ถ้า sla time ไม่เกินที่กำหนด 24 ชั่วโมง ขึ้น Y ถ้าเกิน N
        // เอาเวลาที่กด รับเรื่อง - วันที่สร้าง log 1 คิดมาเป็นจำนวนนาที

        // 1 วันมี 1440 นาที
        let presentDateTime:any = dayjs().format("YYYY-MM-DD HH:mm:ss")

        let SlaVal = 'Y'    // Y = ไม่เกิน      N = เกิน
        let SlaTime = "0"
        let TrailDate:any

        let res_data_trail = await axios.get(
            majorCareUrl + `/majorcare/repairJobTrailMDPC/findRepairTrailByJob?jid=` + id ,
            { headers: {  } }).then((res) => {
                let dataTrail: any = res?.data;
                console.log('dataTrail', dataTrail[0])

                TrailDate = dataTrail ? dayjs(dataTrail[0].create_date).format("YYYY-MM-DD HH:mm:ss") : dayjs().format("YYYY-MM-DD HH:mm:ss")
                // console.log('TrailDate', TrailDate)
                // console.log('presentDateTime', presentDateTime)

                const startDateTime = dayjs(TrailDate);
                const endDateTime = dayjs(presentDateTime);
                const diffInMinutes = endDateTime.diff(startDateTime, 'minute');
                // console.log(diffInMinutes);

                if(diffInMinutes > 1440 ){ // ถ้าเกิน 1 วัน
                    SlaVal = 'N'
                }
                SlaTime = diffInMinutes.toString()

            });

            // console.log('SlaTime', SlaTime)

        // ================================= UPDATE JOB's STAT ======================
        let data = {
            "status_id": "649859e7515b80b78fe13b86",  // รับเรื่องแจ้งซ่อม
            "status_name": "รับเรื่องแจ้งซ่อม",  // รับเรื่องแจ้งซ่อม
            "sla": {
                "sla": SlaVal,
                "sla_time": SlaTime,
            }
        };
        let res = await axios.patch(majorCareUrl + "/majorcare/repairJobMDPC/updateRepairjob?id=" + id, data, { headers: { } });
        // console.log('res', res)

        // ================================= CREATE TRAIL ======================

        let dataTrail = {
            "repair_job" : id,
            "status": "649859e7515b80b78fe13b86", // รับเรื่องแจ้งซ่อม
            "status_name": "รับเรื่องแจ้งซ่อม",
            "sub_status": null,
            "appoint_start": "",
            "appoint_end": "",
            // "assign": {
            //     "name":"",
            //     "lastname":""
            // },
            "sla": {
                "sla": SlaVal,
                "sla_time": SlaTime
            },
            "remark": "",
            "create_by": Identity_data?.user_profile,
            "hod_status": {
                "hod_id": '',
                "hod_name": '',
                "hod_app_status": '', // A = approve, R = not approve 
                "hod_remark": '',
                "create_date": ''
            }
            // "create_date": dayjs()
        }
        let res_trail = await axios.post(majorCareUrl + "/majorcare/repairJobTrailMDPC", dataTrail, { headers: { } });
        // console.log('res_trail', res_trail)

        setCountInterval(0)
        // setDataList([])
        setDataDetailList([])

        successNoti('200', 'success');
    }

    // const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
    //     resource: "pp-types",
    //     metaData: {
    //         populate: [
    //             '*',
    //         ],
    //     },
    //     pagination: {
    //         pageSize: 10,
    //     },
    //     queryOptions: {
    //         onSuccess: (data:any) => {
    //             // console.log('data xxxx : ', data.data)
    //             getDataK();
    //         },
    //     },
    // });

    useEffect(() => {
        if (countInterval <= 1) {
            const interval = setInterval(() => {
                setCountInterval(prev => prev + 1);
                if(countInterval === 1){
                    // refetch();
                    getDataK();
                }

            }, 1000)
            return () => clearInterval(interval)
        }
    }, [countInterval])

    useEffect(() => {
        getDataK();
    }, []);

    async function updateStatus(id:any, val:any){
        // console.log('id', id)
        let data = {
            "is_active": val,
        }
        let res_update = await axios.patch(
            majorCareUrl + `/majorcare/repairJobMDPC/updateRepairjob?id=` + id , data ,
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                //console.log('dataLoad Check', dataLoad)
            }
        );
        refreshDataSource()
    }

    const [refreshFlag, setRefreshFlag] = useState(false);

    const clearDataSource = () => {
        // setDataList([])
        setDataDetailList([])
    }

    const refreshDataSource = () => {
        clearDataSource()
        setCountInterval(0)
        setRefreshFlag(!refreshFlag);

    };

    function showDelConfirm(id:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Delete Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to Delete this data.',
            okText  : 'Confirm',
            onOk() {
                updateStatus(id, false);
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 2000); // orginal = 1000
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        // console.log('id', id)
        confirm({
            title   : 'Restore Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to restore this data.',
            okText  : 'Confirm',
            onOk() {
                updateStatus(id, true);
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 2000); // orginal = 1000
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    const columns:any = [
        {
            title: 'JOB NO.',
            dataIndex: 'job_id',
            key: 'job_id',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a.job_obj[0]?.job_id.localeCompare(b.job_obj[0]?.job_id),
            render: (value:any, record:any) => {
                return (
                    <>
                        {/* <div>{record ? record.job_id !== null ? record.job_id : '-': '-'}</div> */}
                        <div>{record ? record.job_obj[0].job_id !== null ? record.job_obj[0].job_id : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'PROPERTY',
            // dataIndex: 'name',
            key: 'property',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a.job_obj[0]?.request_by?.unitDetail?.property_name.localeCompare(b.job_obj[0]?.request_by?.unitDetail?.property_name),
            render: (value:any, record:any) => {
                return (
                    <>
                        {/* <div>{record ? record.property_obj.length > 0 ? record.property_obj[0].property_name : '-': '-'}</div> */}
                        <div>{record ? record.job_obj[0].request_by ? record.job_obj[0].request_by.unitDetail.property_name : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'UNIT NO.',
            dataIndex: 'unit',
            key: 'unit',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.request_by?.unitDetail?.unit_name < b?.job_obj[0]?.request_by?.unitDetail?.unit_name) {return -1}
                if (a?.job_obj[0]?.request_by?.unitDetail?.unit_name > b?.job_obj[0]?.request_by?.unitDetail?.unit_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        {/* <div>{record ? record.unit_obj.length > 0 ? record.unit_obj[0].unit_name : '-': '-'}</div> */}
                        <div>{record ? record.job_obj[0].request_by ? record.job_obj[0].request_by.unitDetail.unit_name : '-': '-'}</div>

                    </>
                );
            },
            // sorter: (a:any, b:any) => a.createBy.first_name.localeCompare(b.createBy.first_name),
        },
        {
            title: 'รายละเอียด',
            dataIndex: 'details',
            key: 'details',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.zone_obj[0]?.zone_name < b?.zone_obj[0]?.zone_name) {return -1}
                if (a?.zone_obj[0]?.zone_name > b?.zone_obj[0]?.zone_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        {/* <div>{record ? record.unit_obj.length > 0 ? record.unit_obj[0].unit_name : '-': '-'}</div> */}
                        {/* <div>{record ? record.unit.attributes !== undefined ? record.unit.attributes.unit_name : '-': '-'}</div> */}

                        <div>{record ? record.zone_obj[0] !== undefined ? record.zone_obj[0].zone_name : '-': '-'} - {record ? record.category_obj[0] !== undefined ? record.category_obj[0].category_name : '-': '-'} - {record ? record.category_detail_obj[0] !== undefined ? record.category_detail_obj[0].category_detail_name : '-': '-'} </div>

                    </>
                );
            },
            // sorter: (a:any, b:any) => a.createBy.first_name.localeCompare(b.createBy.first_name),
        },
        {
            title: 'CUSTOMER NAME',
            // dataIndex: 'updateBy',
            key: 'cus_name',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.request_by?.attributes?.first_name < b?.job_obj[0]?.request_by?.attributes?.first_name) {return -1}
                if (a?.job_obj[0]?.request_by?.attributes?.first_name > b?.job_obj[0]?.request_by?.attributes?.first_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{
                            record ? 
                                record.job_obj[0].request_by.attributes !== undefined ?
                                    record.job_obj[0].request_by.attributes.first_name + " " + record.job_obj[0].request_by.attributes.last_name  
                                : '-'
                                : '-'
                            }</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        // {
        //     title: 'TRANSFERRED DATE',
        //     dataIndex: 'transfer_date',
        //     key: 'transfer_date',
        //     render: (value:any, record:any) => {
        //         return (
        //             <>
        //                 <div>{record ? record.request_by ? record.request_by.unitDetail ? dayjs(record.request_by.unitDetail.transfer_date).format("DD/MM/YY") : '-' : '-': '-'}</div>
        //             </>
        //         );
        //     },
        //     // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        // },
        {
            title: 'CREATED DATE',
            dataIndex: 'create_date',
            key: 'create_date',
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.create_date < b?.job_obj[0]?.create_date) {return -1}
                if (a?.job_obj[0]?.create_date > b?.job_obj[0]?.create_date) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].create_date !== null ? dayjs(record.job_obj[0].create_date).format("DD/MM/YY HH:mm") : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.create_date.localeCompare(b.create_date),
        },
        {
            title: 'CREATED BY',
            dataIndex: 'create_by',
            key: 'create_by',
            width: 150,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.create_by?.first_name < b?.job_obj[0]?.create_by?.first_name) {return -1}
                if (a?.job_obj[0]?.create_by?.first_name > b?.job_obj[0]?.create_by?.first_name) {return 1}
                return (0)
            },
            render: (createBy:any, record:any) => {
                return (
                    <>
                        <div >{record ? record?.job_obj[0]?.create_by ? record?.job_obj[0]?.create_by?.first_name  : "-" : "-"} {record?.job_obj[0]?.create_by?.last_name ? record?.job_obj[0]?.create_by?.last_name : ""}</div>
                    </>
                );
            },
        },
        {
            title: 'UPDATE BY',
            dataIndex: 'update_by',
            key: 'update_by',
            width: 150,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.update_by?.first_name < b?.job_obj[0]?.update_by?.first_name) {return -1}
                if (a?.job_obj[0]?.update_by?.first_name > b?.job_obj[0]?.update_by?.first_name) {return 1}
                return (0)
            },
            render: (updateBy:any, record:any) => {
                return (
                    <>
                        <div >{record ? record?.job_obj[0]?.update_by ? record?.job_obj[0]?.update_by?.first_name  : "-" : "-"} {record?.job_obj[0]?.update_by?.last_name ? record?.job_obj[0]?.update_by?.last_name : ""}</div>
                    </>
                );
            },
        },
        {
            title: 'Last Update',
            dataIndex: 'update_date',
            key: 'update_date',
            width: 150,
            align: 'center',
            sorter: (a:any, b:any) => a.job_obj[0]?.update_date.localeCompare(b.job_obj[0]?.update_date),
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].update_date !== null ? dayjs(record.job_obj[0].update_date).format("DD/MM/YY HH:mm") : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            key: 'status',
            fixed: 'right',
            align: 'center',
            // sorter: (a:any, b:any) => {
            //     if (a?.status < b?.status) {return -1}
            //     if (a?.status > b?.status) {return 1}
            //     return (0)
            // },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>
                            {
                                record ? 
                                    record.status !== null ?
                                        // <Tag>รอดำเนินการ</Tag> 
                                        <Tag className="tag_mjc_stat"><TextField value={'รอดำเนินการ'}  /></Tag>

                                    : '-'
                                : '-'
                            }
                        </div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        // {
        //     title: 'รับเรื่องแจ้งซ่อม',
        //     dataIndex: 'status',
        //     key: 'status',
        //     // fixed: "",
        //     render: (value:any, record:any) => {
        //         // console.log('record xxx', record)
        //         return (
        //                 record.job_obj[0].is_active === true ? 
        //                     <>
        //                         <div 
        //                             style={{
        //                                 textAlign: 'center',
        //                                 verticalAlign: 'center',
        //                                 // lineHeight: '50px',
        //                                 fontSize:'12px',
        //                             }}
        //                         >
        //                             <CheckCircleOutlined style={{color:'#A8A8A8',fontSize:24}}  onClick={() => { showAcknowledge(record.job_obj[0]._id) }} />
        //                             <br />
        //                             รอดำเนินการ
        //                         </div>
        //                     </>
        //                 :
        //                         <>
        //                             <div 
        //                                 style={{
        //                                     textAlign: 'center',
        //                                     verticalAlign: 'center',
        //                                     // lineHeight: '50px',
        //                                     fontSize:'12px',
        //                                 }}
        //                             >
        //                                 <CheckCircleOutlined style={{color:'#A8A8A8',fontSize:24}}  onClick={() => { }} disabled/>
        //                                 <br />
        //                                 รอดำเนินการ
        //                             </div>
        //                         </>
        //         )
        //     },
        //     // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        // },
        {
            title: 'ACTION',
            dataIndex: '',
            key: 'action',
            fixed: 'right',
            width: 150,
            align: 'center',
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        {
                            record.job_obj[0].is_active === true ? 
                                <>
                                    {/* {
                                        record.is_checklist_empty == true &&
                                        <Tooltip title="No template">
                                            <WarningOutlined style={{ color: "#FFE58F", fontSize: '20px', background: 'white', padding: '4px', }} />
                                        </Tooltip>
                                    } */}
                                    {/* <ShowButton
                                        hideText
                                        size="small"
                                        onClick={() => edit("mjc-job-newjob", record._id + "_" + record.job_id )}
                                        // disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                        style={{
                                            marginRight:'4px'
                                        }}
                                    /> */}
                                  

                                <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <div 
                                        style={{
                                            textAlign: 'center',
                                            verticalAlign: 'center',
                                            // lineHeight: '50px',
                                            fontSize:'6px',
                                            marginRight: '5px',
                                            marginTop: '5px'
                                        }}
                                    >
                                        <CheckCircleOutlined style={{color:'#A8A8A8',fontSize:24}}  onClick={() => { showAcknowledge(record.job_obj[0]._id) }} />
                                        <br />
                                        รอดำเนินการ
                                    </div>
                                    <ShowButton
                                        hideText
                                        size="small"
                                        style={{marginRight:'5px'}}
                                        onClick={() => edit("repair-mdpc-new-job-admin", record._id + "_" + record.job_id )}
                                        // disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                    />
                                    <Button 
                                        size="small" 
                                        title="Delete" 
                                        icon={<Icons.DeleteOutlined />} 
                                        onClick={() => { showDelConfirm(record.job_id) }} 
                                        danger
                                    ></Button>
                                </Row>
                                </>
                            :
                                <>
                                    <Button 
                                        size="small" 
                                        title="Active" 
                                        icon={<Icons.RetweetOutlined />} 
                                        onClick={() => { showUnlockConfirm(record.job_id) }} 
                                    ></Button>
                                </>
                        }
                    </>
                );
            },
        },
    ];

    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    const [pageSize, setPageSize] = useState(10); // Default page size
    // const [pageSizeInActive, setPageSizeInActive] = useState(10); // Default page size
    const handlePageSizeChange = (current:any, size:any) => {
        setPageSize(size);
    };
  
    
    return <>
            {/* <Row gutter={12} style={{float:'right'}}>
                <Col span={2}>
                    <Form.Item style={{marginRight: "0",}}>
                        <CreateButton 
                            id="create_btn" 
                            onClick={() => create("repair-mdpc-new-job-admin")}
                            // disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }
                        />
                    </Form.Item>
                </Col>
            </Row> */}

        <Row gutter={12}>
            <Col span={24}>
                <Form layout="vertical" 
                    onFinish={(values:any) => {}}
                    form = {form}
                >
                    <Row gutter={8} style={{width: "100%"}}>
                        <Col className="gutter-row">
                            <Form.Item
                                name="property"
                                // label="Search"
                                style={{width: "200px", marginRight: "0"}}
                            >
                                <Select 
                                    placeholder="Property" 
                                    {...selProperty} allowClear 
                                    style={{borderRadius: 5, position:'relative'}} 
                                    suffixIcon={<Icons.SearchOutlined/>}
                                    onChange={(value:any, record:any) => {
                                        setselProp({id:value, label: record?.label})
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row">
                            <Form.Item
                                
                                name="unit"
                                style={{width: "120px", marginRight: "0"}}
                            >
                                <Select {...SelUnit}
                                    placeholder="Unit" 
                                    size="middle" 
                                    style={{borderRadius: 5, position:'relative'}} 
                                    // onSelect={(e: any)=>setUnitID(e)}
                                    suffixIcon={<Icons.SearchOutlined/>}
                                    onChange={(value:any, record:any) => { 
                                        setUnit({id:value, label: record?.label})
                                    }}
                                    onClear={()=> {
                                        setUnit(undefined);
                                        form.resetFields(["unit"]);
                                    }}
                                    allowClear
                                    showSearch
                                />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row">
                            <Form.Item name="template_name"  style={{width: "150px", marginRight: "0"}}>
                                <Input 
                                    style={{borderRadius: 5, position:'relative'}} 
                                    placeholder="Job No." 
                                    onChange={(e) => { if(e.target.value.length > 0){ setnameSearch(e?.target?.value)}else{setnameSearch("")}}} 
                                    suffix={<Icons.SearchOutlined/>} 
                                allowClear/>
                            </Form.Item>
                        </Col>

                        <Col className="gutter-row">
                            <Form.Item name="cust_name"  style={{width: "180px", marginRight: "0"}}>
                                <Input 
                                    style={{borderRadius: 5, position:'relative'}} 
                                    placeholder="Customer name" 
                                    onChange={(e) => setKeepCustomerName(e.target.value)} 
                                    suffix={<Icons.SearchOutlined/>} 
                                    allowClear
                                />
                            </Form.Item>
                        </Col>

                        <Col className="gutter-row">
                            <Form.Item name="detail"  style={{width: "180px", marginRight: "0"}}>
                                <Input 
                                    style={{borderRadius: 5, position:'relative'}} 
                                    placeholder="รายละเอียด" 
                                    onChange={(e) => setKeepDataDetail(e.target.value)} 
                                    suffix={<Icons.SearchOutlined/>} 
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                        
                        <Col className="gutter-row">
                            <Form.Item >
                                <Button type="primary" style={{borderRadius: "5px"}} onClick={()=> onSearchtable(dataDetailList,"S", "xxxxxx")}>
                                    <Icons.SearchOutlined /> Search
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col  className="gutter-row">
                            <Form.Item >
                                <Button 
                                    onClick={() => {
                                        onSearchtable(dataDetailList,"R", "xxxx")
                                    }} 
                                    style={{borderRadius: "5px"}}
                                    icon={<Icons.UndoOutlined />}
                                >
                                    Reset
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>

        <Row style={{float:'right'}}>
            <Col span={24}>
                <Form.Item >
                    <CreateButton 
                        id="create_btn" 
                        onClick={() => create("repair-mdpc-new-job-admin")}
                        // disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }
                    />
                </Form.Item>
            </Col>
        </Row>

        <div>
            {
                dataDetailList.length > 0 ?
                    <Table 
                        loading={IsLoading}
                        // dataSource={dataList} 
                        // dataSource={dataList.length > 0 ? dataList : dataBlank} 
                        dataSource={dataDetailList.length > 0 ? dataDetailList.reverse() : dataBlank} 
                        columns={columns} 
                        onChange={onChange} 

                        // pagination={{ size: "small", position: [ bottom] }} 
                        // pagination={{ size: "small", position: [ 'bottomRight'] }} 
                        pagination={{
                            size: "small",
                            position: ['bottomRight'],
                            // showTotal: showTotal, 
                            pageSizeOptions: ['10', '20', '50'], // Optional - Customize the available page sizes
                            showSizeChanger: true, // Optional - Show page size changer dropdown
                            // showQuickJumper: true, // Optional - Show quick jumper input
                            // pageSize: 10, // Optional - Default page size
                            pageSize: pageSize,
                            onChange: handlePageSizeChange,
                        }}
                        // style={{marginTop:"-20px"}}
                        scroll={{ x: 2000 }}
                    />
                :
                    <Table  
                        columns={columns} 
                        onChange={onChange} 
                        loading={IsLoading}
                        // pagination={{ size: "small", position: [ bottom] }} 
                        pagination={{ size: "small", position: [ 'bottomRight'] }} 
                    />
            }
        </div>

        <Row style={{float:'right', paddingTop:'10px'}}>
            <Col span={24} >
                {t("repair-stat.title")} {countNewJob?.length}
            </Col>
        </Row>
    </>
}